import { Entity } from 'components/Entity/Entity'
import React from 'react'
import {
  Box,
  Center,
  Flex,
  HStack,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { formatNumber } from 'utils/formatNumber'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Documents } from './tabs/Documents'
import { Progress } from './tabs/Progress'
import { useOrderData, useWebSocketOrderData } from 'state/useOrder'
import { Details } from './tabs/Details'
import { useAtomValue } from 'jotai'
import { orderIdAtom } from 'state/atoms'
import { Icon } from '@chakra-ui/icon'
import { BsTelegram } from 'react-icons/bs'
import { useCurrentUser } from '../../../state/useCurrentUser'
import { History } from './tabs/History'

interface OrderEntityBodyProps {
  id: string | undefined
  onBack: () => void
}

export const OrderEntityBody: React.FC<OrderEntityBodyProps> = ({
  id,
  onBack,
}) => {
  const { orderData, documentsData, messagesData } = useOrderData(id)
  const { isControl } = useCurrentUser()
  useWebSocketOrderData(id)

  if (!orderData) {
    return <Center flex={1}>Заявка не найдена</Center>
  }

  return (
    <Entity
      header="Заявка"
      description={
        <Stack>
          <HStack>
            <Box color="gray.400">№:</Box>
            <Box>{`${formatNumber(orderData?.orderNumber ?? 0)} от ${moment(orderData?.createdAt).format('DD.MM.YYYY HH:mm')}`}</Box>
          </HStack>
          {orderData?.managerUser?.telegramUsername && !isControl && (
            <HStack
              spacing="6px"
              color="blue.500"
              cursor="pointer"
              transition="0.3s"
              _hover={{
                color: 'blue.400',
              }}
              onClick={() =>
                window.open(
                  `https://t.me/${orderData?.managerUser?.telegramUsername}`
                )
              }
            >
              <Icon as={BsTelegram} boxSize="14px" />
              <Box fontSize="0.84rem">Связаться с менеджером</Box>
            </HStack>
          )}
        </Stack>
      }
      onBack={onBack}
    >
      <Tabs variant="soft-rounded" colorScheme="gray">
        <TabList gap="6px">
          <Tab {...tabStyle}>Состояние</Tab>
          {messagesData && !isEmpty(messagesData) && (
            <Tab {...tabStyle}>Сообщения</Tab>
          )}
          {!isEmpty(documentsData) && <Tab {...tabStyle}>Документы</Tab>}
          <Tab {...tabStyle}>Детали</Tab>
        </TabList>
        <TabPanels>
          <TabPanel padding="20px 0">
            <Progress />
          </TabPanel>
          {messagesData && !isEmpty(messagesData) && (
            <TabPanel padding="20px 0">
              <History />
            </TabPanel>
          )}
          {documentsData && !isEmpty(documentsData) && (
            <TabPanel padding="20px 0">
              <Documents />
            </TabPanel>
          )}
          <TabPanel padding="20px 0">
            <Details />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Entity>
  )
}

interface OrderEntityProps extends OrderEntityBodyProps {}

export const OrderEntity: React.FC<OrderEntityProps> = ({ ...props }) => {
  const { isCalled, isLoading } = useOrderData(props?.id)
  const orderId = useAtomValue(orderIdAtom)

  if (!isCalled || isLoading || orderId !== props?.id) {
    return (
      <Flex justifyContent="center" flex={1} padding="60px 20px 20px 20px">
        <Stack
          flexDirection="column"
          maxW="640px"
          width="100%"
          pb="30px"
          spacing="24px"
        >
          <Stack>
            <Skeleton height="38px" borderRadius="4px" width="200px" />
            <Skeleton
              height="23px"
              borderRadius="4px"
              maxW="340px"
              width="100%"
            />
          </Stack>
          <Stack spacing="20px">
            <HStack spacing="6px" opacity={0.5}>
              <Skeleton height="40px" borderRadius="4px" width="150px" />
              <Skeleton height="40px" borderRadius="4px" width="90px" />
              <Skeleton height="40px" borderRadius="4px" width="120px" />
            </HStack>
            <Skeleton
              opacity={0.5}
              height="4px"
              borderRadius="4px"
              width="100%"
            />
            <Stack spacing="24px" opacity={0.4}>
              <Stack spacing="6px">
                <Skeleton height="22px" borderRadius="4px" width="80px" />
                <Skeleton height="36px" borderRadius="4px" width="200px" />
              </Stack>
              <Skeleton height="80px" borderRadius="14px" width="100%" />
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    )
  }

  return <OrderEntityBody {...props} />
}

const tabStyle = {
  mb: '4px',
  borderRadius: '10px',
  fontSize: '1rem',
  padding: '8px 16px',
  _selected: { color: 'black', backgroundColor: 'gray.100' },
}
