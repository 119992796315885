import {
  Box,
  Button,
  Center,
  Flex,
  PinInput,
  PinInputField, Spinner,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import {
  StartRegistrationMutationVariables,
  useConfirmRegistrationMutation,
  useStartRegistrationMutation,
} from 'api/graphql'
import { InputGroupForm } from '../../uikit/InputGroup'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { InputMaskForm } from '../../uikit/InputMask'
import { Modal } from '../../uikit/Modal'
import React, { useEffect, useRef, useState } from 'react'
import { Checkbox } from '../../uikit/Checkbox'
import { useCurrentUser } from '../../state/useCurrentUser'
import { DocPrivacyPolicyModal } from '../../shared/modals/DocPrivacyPolicyModal'
import { DocOfferModal } from '../../shared/modals/DocOfferModal'
import { DocPersonalDataModal } from '../../shared/modals/DocPersonalDataModal'

const formSchema = Yup.object().shape({
  name: Yup.string().required('Введите имя'),
  phone: Yup.string().required('Введите телефон'),
  password: Yup.string().required('Введите пароль'),
  passwordConfirm: Yup.string()
    .label('confirm password')
    .required('Введите пароль')
    .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
  email: Yup.string().test('email', 'Введите правильную почту', (e) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(e))
  ),
})

export const Registration = () => {
  const toast = useToast()
  const [startRegistrationMutation, { loading: isLoading }] =
    useStartRegistrationMutation()
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const [phone, setPhone] = useState<string | null>(null)
  const [isPersonalDataCheck, setIsPersonalDataCheck] = useState(false)
  const [isOfferCheck, setIsOfferCheck] = useState(false)
  const [isPrivacyPolicyCheck, setIsPrivacyPolicyCheck] = useState(false)
  const [resendData, setResendData] = useState<StartRegistrationMutationVariables | null>(null)

  const [isDocPrivacyPolicyOpen, setIsDocPrivacyPolicyOpen] = useState(false)
  const [isDocOfferOpen, setIsDocOfferOpen] = useState(false)
  const [isDocPersonalDataOpen, setIsDocPersonalDataOpen] = useState(false)

  const handleClose = () => {
    setPhone(null)
    setIsOpenConfirm(false)
  }

  const handleForm = async (values: FormikValues) => {
    try {
      const phoneValue = `+${values?.phone?.replace(/\D/g, '')}`
      const variables = {
        name: values?.name,
        phone: phoneValue,
        email: values?.email,
        password: values?.password,
      }
      setResendData(variables)
      await startRegistrationMutation({
        variables
      })
      setPhone(phoneValue)
      setIsOpenConfirm(true)
    } catch (e) {
      toast({
        title: `Ошибка регистрации`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  const handleResendCode = async () => {
    if (!resendData) return
    await startRegistrationMutation({
      variables: resendData
    })
  }

  return (
    <>
      <Center flex={1}>
        <Flex
          backgroundColor="white"
          maxW="490px"
          width="100%"
          borderRadius="20px"
          padding="40px 50px 50px"
          borderWidth={1}
        >
          <Stack width="100%" spacing="30px">
            <Stack>
              <Box fontSize="1.8rem" fontWeight={700}>
                Регистрация пользователя
              </Box>
              <Box>
                У вас уже есть аккаунт?{' '}
                <Box as={Link} layerStyle="link" to="/login">
                  Войти
                </Box>
              </Box>
            </Stack>
            <Formik
              validationSchema={formSchema}
              initialValues={{
                name: '',
                phone: '',
                email: '',
                password: '',
                passwordConfirm: '',
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Stack spacing="28px">
                    <Stack spacing="14px">
                      <Field
                        size="lg"
                        name="name"
                        label="Контактное лицо"
                        placeholder="Иванов Иван Иванович"
                        component={InputGroupForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        size="lg"
                        name="phone"
                        label="Телефон"
                        placeholder="+7 (000) 000-00-00"
                        component={InputMaskForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        size="lg"
                        name="email"
                        placeholder="name@example.ru"
                        label="Электронная почта"
                        component={InputGroupForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        size="lg"
                        type="password"
                        name="password"
                        label="Пароль"
                        component={InputGroupForm}
                        isDisabled={isLoading}
                      />
                      <Field
                        size="lg"
                        type="password"
                        name="passwordConfirm"
                        label="Повторите пароль"
                        component={InputGroupForm}
                        isDisabled={isLoading}
                      />
                    </Stack>
                    <Stack>
                      <Checkbox
                        isChecked={isPersonalDataCheck}
                        onChange={(e) => setIsPersonalDataCheck(e.target.checked)}
                      >
                        <Box fontSize="0.84rem" lineHeight="1.3">
                          Согласие на
                          <Box as="span" layerStyle="link" onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setIsDocPersonalDataOpen(true)
                          }}>
                            {' '}
                            обработку моих персональных данных
                          </Box>
                        </Box>
                      </Checkbox>
                      <Checkbox
                        isChecked={isOfferCheck}
                        onChange={(e) => setIsOfferCheck(e.target.checked)}
                      >
                        <Box fontSize="0.84rem" lineHeight="1.3">
                          Согласие с
                          <Box as="span" layerStyle="link" onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setIsDocOfferOpen(true)
                          }}>
                            {' '}
                            офертой
                          </Box>
                        </Box>
                      </Checkbox>
                      <Checkbox
                        isChecked={isPrivacyPolicyCheck}
                        onChange={(e) => setIsPrivacyPolicyCheck(e.target.checked)}
                      >
                        <Box fontSize="0.84rem" lineHeight="1.3">
                          Согласие с
                          <Box as="span" layerStyle="link" onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setIsDocPrivacyPolicyOpen(true)
                          }}>
                            {' '}
                            политикой конфиденциальности
                          </Box>
                        </Box>
                      </Checkbox>
                    </Stack>
                    <Stack spacing="14px">
                      <Button
                        isDisabled={
                          !isOfferCheck || !isPrivacyPolicyCheck || !isPersonalDataCheck
                        }
                        size="lg"
                        isLoading={isLoading}
                        type="submit"
                        variant="primary"
                      >
                        Отправить
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Stack>
        </Flex>
      </Center>
      <ConfirmModal
        phone={phone}
        isOpen={isOpenConfirm}
        onClose={handleClose}
        onResendCode={handleResendCode}
      />
      <DocPrivacyPolicyModal
        isOpen={isDocPrivacyPolicyOpen}
        onClose={() => setIsDocPrivacyPolicyOpen(false)}
      />
      <DocOfferModal
        isOpen={isDocOfferOpen}
        onClose={() => setIsDocOfferOpen(false)}
      />
      <DocPersonalDataModal
        isOpen={isDocPersonalDataOpen}
        onClose={() => setIsDocPersonalDataOpen(false)}
      />
    </>
  )
}

interface ConfirmModalProps {
  isOpen: boolean
  phone: string | null
  onClose: () => void
  onResendCode: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, phone, onClose, onResendCode }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const firstPin = useRef<HTMLInputElement>(null)
  const [pin, setPin] = useState('')
  const [confirmRegistrationMutation] = useConfirmRegistrationMutation()
  const { getCurrentUser } = useCurrentUser()
  const [isLoading, setIsLoading] = useState(false)
  const [timeCounter, setTimeCounter] = useState(30)

  const handleConfirm = async (code: string) => {
    try {
      if (!phone) return
      setIsLoading(true)
      await confirmRegistrationMutation({
        variables: {
          code,
          phone,
        },
      })
      await getCurrentUser()
      navigate('/orders/create')
      toast({
        title: `Регистрация прошла успешно`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    } catch (e) {
      firstPin.current?.focus()
      setPin('')
      toast({
        title: `Ошибка регистрации`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const authorizationResendCodeText = `Запросить код повторно через $timeCounter секунд`.replace(
    '$timeCounter',
    timeCounter.toString()
  )

  useEffect(() => {
    if (!isOpen) return
    const intervalId = setInterval(
      () =>
        setTimeCounter((state) => {
          if (state !== 0) {
            return state - 1
          } else {
            return 0
          }
        }),
      1000
    )

    return () => {
      clearTimeout(intervalId)
    }
  }, [isOpen])

  const resendClick = () => {
    setTimeCounter(30)
    onResendCode()
  }

  useEffect(() => {
    if (!isOpen) {
      setPin('')
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isHideClose={isLoading}
      containerProps={{
        maxW: '380px',
      }}
    >
      <Stack
        spacing="18px"
        pb="28px"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Stack spacing="2px">
          <Box fontWeight={700} fontSize="1.4rem">
            Введите код
          </Box>
          <Box>который отправлен на ваш номер</Box>
        </Stack>
        {isLoading ? (
          <Center height="40px">
            <Spinner boxSize="18px"/>
          </Center>
        ) : (
          <Flex justifyContent="space-between" width="200px">
            <PinInput
              type="number"
              value={pin}
              onChange={setPin}
              onComplete={handleConfirm}
            >
              <PinInputField ref={firstPin} autoFocus data-cy="pin-1" />
              <PinInputField data-cy="pin-2" />
              <PinInputField data-cy="pin-3" />
              <PinInputField data-cy="pin-4" />
            </PinInput>
          </Flex>
        )}
        {timeCounter !== 0 ? (
          <Box fontSize="0.78rem" color="gray">
            {authorizationResendCodeText}
          </Box>
        ) : (
          <Button
            variant="link"
            size="sm"
            onClick={resendClick}
          >
            Отправить код повторно
          </Button>
        )}
      </Stack>
    </Modal>
  )
}
