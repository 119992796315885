import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import axios from 'axios'

const endpoint = '/api/files/order'

export type DocumentType =
  | 'Other'
  | 'Invoice'
  | 'Contract'
  | 'SignedContract'
  | 'DealDocuments'
  | 'PaymentInvoice'
  | 'Draft'
  | 'ContractAppendix'
  | 'PaidInvoice'
  | 'Act'
  | 'SignedAct'
  | 'Swift'
  | 'PayerDetails'

type Upload = {
  file: Blob
  documentType: DocumentType
  orderId: string
}

export const useUpload = () => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const upload = async ({ file, documentType = 'Other', orderId }: Upload) => {
    try {
      setIsLoading(true)
      setUploadProgress(0)
      const data = new FormData()

      if (file) {
        data.append('DocumentType', documentType)
        data.append('File', file)
      }

      const response = await axios.post(
        `${endpoint}/${orderId}/documents`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setUploadProgress(percentCompleted)
            }
          },
        }
      )
      return response.data
    } catch (e) {
      console.error(e)
      toast({
        title: `Ошибка загрузки файла`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
      setUploadProgress(0)
    }
  }
  return {
    upload,
    isLoading,
    uploadProgress,
  }
}
