import { InputGroup } from 'uikit/InputGroup'
import React, { useState } from 'react'
import useDaData from './hooks/useCompanyDaData'
import { useDebounce } from 'use-debounce'
import { Box, Flex, Grid, Skeleton, Stack } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icon'
import { LuBuilding2 } from 'react-icons/lu'
import { DaDataSuggestion } from './types'

export interface CompanyDaDataResult {
  name: string
  inn: string
  kpp: string
  legalAddress: string
  actualAddress: string
  ceo: string
  bic: string
  bank: string
  correspondentAccount: string
  checkingAccount: string
}

interface CompanyDaDataProps {
  onChange: (data: CompanyDaDataResult) => void
}

export const CompanyDaData: React.FC<CompanyDaDataProps> = ({ onChange }) => {
  const [value, setValue] = useState('')
  const [query] = useDebounce(value, 400)
  const { data, loading: isLoading } = useDaData(query)

  return (
    <Stack spacing="20px">
      <InputGroup
        size="lg"
        placeholder="Введите ИНН для поиска"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {isLoading ? (
        <Stack spacing={0}>
          <ItemLoading />
          <ItemLoading />
          <ItemLoading />
        </Stack>
      ) : (
        <Stack spacing={0}>
          {data?.suggestions.map((item, index) => (
            <Item key={index} data={item} onChange={onChange} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

interface ItemProps {
  data: DaDataSuggestion
  onChange: (data: CompanyDaDataResult) => void
}

const Item: React.FC<ItemProps> = ({ data, onChange }) => {
  const handleClick = () => {
    onChange({
      name: data?.value,
      inn: data?.data?.inn,
      kpp: data?.data?.kpp,
      legalAddress: data?.data?.address?.unrestricted_value ?? '',
      actualAddress: data?.data?.address?.data?.source ?? '',
      ceo: data?.data?.management?.name ?? '',
      bic: '',
      bank: '',
      correspondentAccount: '',
      checkingAccount: '',
    })
  }

  return (
    <Grid
      onClick={handleClick}
      templateColumns="20px 1fr"
      gap="16px"
      px="20px"
      minH="60px"
      alignItems="center"
      transition="0.2s"
      cursor="pointer"
      borderBottomWidth={1}
      borderColor="gray.100"
      _hover={{
        backgroundColor: 'gray.50',
      }}
      _active={{
        backgroundColor: 'gray.100',
      }}
    >
      <Icon as={LuBuilding2} width="16px" />
      <Stack spacing="4px" lineHeight="1.2">
        <Box>{data?.value}</Box>
        <Box
          color="gray.400"
          fontSize="0.8rem"
        >{`ИНН: ${data?.data?.inn}, КПП: ${data?.data?.kpp}${
          data?.data?.management?.name ? `, ${data?.data?.management?.name}` : ''
        }`}</Box>
      </Stack>
    </Grid>
  )
}

const ItemLoading = () => {
  return (
    <Grid
      borderBottomWidth={1}
      borderColor="gray.100"
      gap="16px"
      px="20px"
      alignItems="center"
      templateColumns="20px 1fr"
      minH="60px"
    >
      <Skeleton width="16px" height="16px" />
      <Stack spacing="4px" lineHeight="1.2">
        <Skeleton maxW="240px" width="100%" height="19px" />
        <Skeleton width="100px" height="15px" />
      </Stack>
    </Grid>
  )
}
