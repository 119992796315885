import { Flex } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icon'
import { LuBuilding2 } from 'react-icons/lu'
import React from 'react'
import { CompanyFragment } from 'api/graphql'
import { EntityListRow } from 'components/Entity/EntityListRow'

interface UserRowProps {
  data: CompanyFragment
}

export const CompanyRow: React.FC<UserRowProps> = ({ data }) => {
  return (
    <EntityListRow
      to={`${data.id}`}
      templateColumns="20px 1fr"
    >
      <Icon as={LuBuilding2} width="16px" />
      <Flex fontSize="0.9rem">{data.name}</Flex>
    </EntityListRow>
  )
}
