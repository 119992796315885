import { Route, Routes } from 'react-router-dom'
import { List } from './routes/List'
import { Create } from './routes/Create'
import { Item } from './routes/Item'

export const Companies = () => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path=":id" element={<Item />} />
      <Route path="create" element={<Create />} />
    </Routes>
  )
}
