import { Alert, AlertIcon, Box, Stack, Progress as ProgressBar } from '@chakra-ui/react'
import { Status } from '../elements/Status'
import { StepUser } from '../components/StepUser'
import React, { useMemo, useState } from 'react'
import { useCurrentUser } from 'state/useCurrentUser'
import { StepControl } from '../components/StepControl'
import { useOrderData } from 'state/useOrder'
import { EOrderStatus } from 'api/graphql'
import { SetStatusModal } from '../../../modals/SetStatusModal'
import { orderStatusProgress } from 'constants/orderStatus'

export const Progress = () => {
  const { isControl } = useCurrentUser()
  const { orderData } = useOrderData()
  const [isOpen, setIsOpen] = useState(false)

  const isShowAlertCourse = useMemo(() => {
    if (!orderData?.status || isControl) return false
    return [
      EOrderStatus.DealCoordination,
      EOrderStatus.InvoicePayment,
      EOrderStatus.PaymentToSupplier,
      EOrderStatus.PaymentVerification,
      EOrderStatus.PaymentToSupplierConfirmed
    ].includes(orderData.status)
  }, [orderData, isControl])

  return (
    <>
      <Stack spacing="24px">
        {orderData && (
          <ProgressBar
            hasStripe
            height="4px"
            borderRadius="full"
            value={orderStatusProgress[orderData.status ?? 10]}
            colorScheme="purple"
            min={0}
            max={200}
          />
        )}
        <Stack spacing="6px">
          <Box fontSize="0.9rem">
            Статус
          </Box>
          <Status />
          {isControl && (
            <Box
              color="blue.500"
              cursor="pointer"
              transition="0.3s"
              fontSize="0.84rem"
              _hover={{
                color: 'blue.400',
              }}
              onClick={() => setIsOpen(true)}
            >
              Сменить статус
            </Box>
          )}
        </Stack>
        {isShowAlertCourse && (
          <Alert status="warning" padding="28px" borderRadius="14px">
            <AlertIcon />
            Курс фиксируется в дату проведение сделки
          </Alert>
        )}
        {isControl ? <StepControl /> : <StepUser />}
      </Stack>
      <SetStatusModal
        isOpen={isOpen}
        status={orderData?.status}
        comment={orderData?.comment ?? ''}
        onClose={() => setIsOpen(false)}
        orderId={orderData?.id}
      />
    </>
  )
}
