import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Json: { input: any; output: any; }
  Long: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BaseResponse = {
  __typename?: 'BaseResponse';
  status: EResponseStatus;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Company = {
  __typename?: 'Company';
  actualAddress?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  ceo?: Maybe<Scalars['String']['output']>;
  checkingAccount?: Maybe<Scalars['String']['output']>;
  correspondentAccount?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  inn?: Maybe<Scalars['String']['output']>;
  kpp?: Maybe<Scalars['String']['output']>;
  legalAddress?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyFilterInput = {
  actualAddress?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CompanyFilterInput>>;
  bank?: InputMaybe<StringOperationFilterInput>;
  bic?: InputMaybe<StringOperationFilterInput>;
  ceo?: InputMaybe<StringOperationFilterInput>;
  checkingAccount?: InputMaybe<StringOperationFilterInput>;
  correspondentAccount?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  inn?: InputMaybe<StringOperationFilterInput>;
  kpp?: InputMaybe<StringOperationFilterInput>;
  legalAddress?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type CompanySortInput = {
  actualAddress?: InputMaybe<SortEnumType>;
  bank?: InputMaybe<SortEnumType>;
  bic?: InputMaybe<SortEnumType>;
  ceo?: InputMaybe<SortEnumType>;
  checkingAccount?: InputMaybe<SortEnumType>;
  correspondentAccount?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  inn?: InputMaybe<SortEnumType>;
  kpp?: InputMaybe<SortEnumType>;
  legalAddress?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum EDocumentType {
  Act = 'ACT',
  Contract = 'CONTRACT',
  ContractAppendix = 'CONTRACT_APPENDIX',
  DealDocuments = 'DEAL_DOCUMENTS',
  Draft = 'DRAFT',
  Invoice = 'INVOICE',
  Other = 'OTHER',
  PaidInvoice = 'PAID_INVOICE',
  PayerDetails = 'PAYER_DETAILS',
  PaymentInvoice = 'PAYMENT_INVOICE',
  SignedAct = 'SIGNED_ACT',
  SignedContract = 'SIGNED_CONTRACT',
  Swift = 'SWIFT'
}

export enum EMessageType {
  FileAttached = 'FILE_ATTACHED',
  StatusChanged = 'STATUS_CHANGED',
  TextMessage = 'TEXT_MESSAGE'
}

export enum EOrderStatus {
  AcceptedForProcessing = 'ACCEPTED_FOR_PROCESSING',
  ActVerification = 'ACT_VERIFICATION',
  ContractCoordination = 'CONTRACT_COORDINATION',
  ContractPreparation = 'CONTRACT_PREPARATION',
  ContractSigned = 'CONTRACT_SIGNED',
  ContractSigning = 'CONTRACT_SIGNING',
  Created = 'CREATED',
  DealApproved = 'DEAL_APPROVED',
  DealCoordination = 'DEAL_COORDINATION',
  Declined = 'DECLINED',
  DocumentsRequested = 'DOCUMENTS_REQUESTED',
  DocumentsVerification = 'DOCUMENTS_VERIFICATION',
  EdiContractWaiting = 'EDI_CONTRACT_WAITING',
  Finished = 'FINISHED',
  InvoicePayment = 'INVOICE_PAYMENT',
  NeedContractCorrection = 'NEED_CONTRACT_CORRECTION',
  NeedDealCorrection = 'NEED_DEAL_CORRECTION',
  PayerApproving = 'PAYER_APPROVING',
  PayerCoordination = 'PAYER_COORDINATION',
  PaymentToSupplier = 'PAYMENT_TO_SUPPLIER',
  PaymentToSupplierConfirmed = 'PAYMENT_TO_SUPPLIER_CONFIRMED',
  PaymentToSupplierFailed = 'PAYMENT_TO_SUPPLIER_FAILED',
  PaymentVerification = 'PAYMENT_VERIFICATION',
  ProposalApproval = 'PROPOSAL_APPROVAL',
  RateApproved = 'RATE_APPROVED',
  RateApproving = 'RATE_APPROVING',
  RateCoordination = 'RATE_COORDINATION'
}

export type EOrderStatusOperationFilterInput = {
  eq?: InputMaybe<EOrderStatus>;
  in?: InputMaybe<Array<EOrderStatus>>;
  neq?: InputMaybe<EOrderStatus>;
  nin?: InputMaybe<Array<EOrderStatus>>;
};

export enum EResponseStatus {
  AccessDenied = 'ACCESS_DENIED',
  EditForbidden = 'EDIT_FORBIDDEN',
  EmailInUse = 'EMAIL_IN_USE',
  IncorrectPassword = 'INCORRECT_PASSWORD',
  IncorrectRequestData = 'INCORRECT_REQUEST_DATA',
  IncorrectStatus = 'INCORRECT_STATUS',
  ItemNotFound = 'ITEM_NOT_FOUND',
  PhoneInUse = 'PHONE_IN_USE',
  RegistrationCodeExpired = 'REGISTRATION_CODE_EXPIRED',
  RegistrationCodeInvalid = 'REGISTRATION_CODE_INVALID',
  Success = 'SUCCESS',
  SystemError = 'SYSTEM_ERROR',
  Unauthorized = 'UNAUTHORIZED'
}

export enum EUserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER'
}

export type EUserRoleOperationFilterInput = {
  eq?: InputMaybe<EUserRole>;
  in?: InputMaybe<Array<EUserRole>>;
  neq?: InputMaybe<EUserRole>;
  nin?: InputMaybe<Array<EUserRole>>;
};

export type FileAttachment = {
  __typename?: 'FileAttachment';
  contentType: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  documentType: EDocumentType;
  fileName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  size: Scalars['Long']['output'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrderTextMessage: BaseResponse;
  adminSetOrderStatus: Order;
  confirmPasswordReset: BaseResponse;
  confirmPhoneUpdate: User;
  confirmRegistration: User;
  login: User;
  logout: BaseResponse;
  managerEdiContractUploaded: Order;
  managerFinishOrder: Order;
  managerInvoicePaymentConfirmed: Order;
  managerInvoiceUploaded: Order;
  managerNeedDocumentsCorrection: Order;
  managerOfferPayer: Order;
  managerOfferRate: Order;
  managerPaymentToSupplierConfirmed: Order;
  managerRemoveDocument: Order;
  managerRequestDocuments: Order;
  managerSendOrderContract: Order;
  managerSendOrderProposal: Order;
  managerSetDealDate: Order;
  orderSetManager: Order;
  saveCompany: Company;
  saveOrder: Order;
  saveUser: User;
  startPasswordReset: BaseResponse;
  startPhoneUpdate: BaseResponse;
  startRegistration: BaseResponse;
  updateEmail: User;
  userAcceptDeal: Order;
  userAcceptOrderProposal: Order;
  userAcceptRate: Order;
  userActSent: Order;
  userApprovePayer: Order;
  userContractSigned: Order;
  userDeclineOrderProposal: Order;
  userDeclinePayer: Order;
  userDeclineRate: Order;
  userInvoicePaid: Order;
  userPaymentToSupplierFailed: Order;
  userPublishOrder: Order;
  userRequestedDocumentsUploaded: Order;
  userSelectSigningMethod: Order;
  userSetNeedContractCorrection: Order;
  userSetNeedDealCorrection: Order;
};


export type MutationAddOrderTextMessageArgs = {
  message: Scalars['String']['input'];
  orderId: Scalars['UUID']['input'];
};


export type MutationAdminSetOrderStatusArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  status: EOrderStatus;
};


export type MutationConfirmPasswordResetArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationConfirmPhoneUpdateArgs = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationConfirmRegistrationArgs = {
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationManagerEdiContractUploadedArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationManagerFinishOrderArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationManagerInvoicePaymentConfirmedArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  paymentDate: Scalars['DateTime']['input'];
  receiptAmount: Scalars['Decimal']['input'];
};


export type MutationManagerInvoiceUploadedArgs = {
  amount: Scalars['Decimal']['input'];
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerNeedDocumentsCorrectionArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerOfferPayerArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerOfferRateArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerPaymentToSupplierConfirmedArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerRemoveDocumentArgs = {
  documentId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerRequestDocumentsArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerSendOrderContractArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerSendOrderProposalArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationManagerSetDealDateArgs = {
  comment: Scalars['String']['input'];
  dealDate: Scalars['DateTime']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationOrderSetManagerArgs = {
  id: Scalars['UUID']['input'];
  managerUserId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationSaveCompanyArgs = {
  actualAddress?: InputMaybe<Scalars['String']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  checkingAccount?: InputMaybe<Scalars['String']['input']>;
  correspondentAccount?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  kpp?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveOrderArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  insurance?: InputMaybe<Scalars['Boolean']['input']>;
  logistics?: InputMaybe<Scalars['Boolean']['input']>;
  tnVed?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<EUserRole>;
  telegramUsername?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStartPasswordResetArgs = {
  phone: Scalars['String']['input'];
};


export type MutationStartPhoneUpdateArgs = {
  email: Scalars['String']['input'];
};


export type MutationStartRegistrationArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationUserAcceptDealArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserAcceptOrderProposalArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserAcceptRateArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserActSentArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserApprovePayerArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserContractSignedArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserDeclineOrderProposalArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationUserDeclinePayerArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationUserDeclineRateArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationUserInvoicePaidArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserPaymentToSupplierFailedArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationUserPublishOrderArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserRequestedDocumentsUploadedArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationUserSelectSigningMethodArgs = {
  edi: Scalars['Boolean']['input'];
  ediId?: InputMaybe<Scalars['String']['input']>;
  ediName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  needPayerApproving: Scalars['Boolean']['input'];
};


export type MutationUserSetNeedContractCorrectionArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationUserSetNeedDealCorrectionArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};

export type Order = {
  __typename?: 'Order';
  amount?: Maybe<Scalars['Decimal']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company: Company;
  companyId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  dealDate?: Maybe<Scalars['DateTime']['output']>;
  edi: Scalars['Boolean']['output'];
  ediId?: Maybe<Scalars['String']['output']>;
  ediName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  insurance: Scalars['Boolean']['output'];
  logistics: Scalars['Boolean']['output'];
  managerUser?: Maybe<User>;
  managerUserId?: Maybe<Scalars['UUID']['output']>;
  orderNumber: Scalars['Int']['output'];
  paperContractSigned: Scalars['Boolean']['output'];
  receiptAmount?: Maybe<Scalars['Decimal']['output']>;
  receiptDate?: Maybe<Scalars['DateTime']['output']>;
  status: EOrderStatus;
  statusComment?: Maybe<Scalars['String']['output']>;
  tnVed?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['UUID']['output'];
};

export type OrderFilterInput = {
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<OrderFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<UuidOperationFilterInput>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  dealDate?: InputMaybe<DateTimeOperationFilterInput>;
  edi?: InputMaybe<BooleanOperationFilterInput>;
  ediId?: InputMaybe<StringOperationFilterInput>;
  ediName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  insurance?: InputMaybe<BooleanOperationFilterInput>;
  logistics?: InputMaybe<BooleanOperationFilterInput>;
  managerUser?: InputMaybe<UserFilterInput>;
  managerUserId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<OrderFilterInput>>;
  orderNumber?: InputMaybe<IntOperationFilterInput>;
  paperContractSigned?: InputMaybe<BooleanOperationFilterInput>;
  receiptAmount?: InputMaybe<DecimalOperationFilterInput>;
  receiptDate?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<EOrderStatusOperationFilterInput>;
  statusComment?: InputMaybe<StringOperationFilterInput>;
  tnVed?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  user?: InputMaybe<UserFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  orderId: Scalars['UUID']['output'];
  status: EOrderStatus;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['UUID']['output'];
};

export type OrderMessage = {
  __typename?: 'OrderMessage';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  documentType?: Maybe<EDocumentType>;
  fileAttachmentId?: Maybe<Scalars['UUID']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  messageType: EMessageType;
  orderId: Scalars['UUID']['output'];
  orderStatus?: Maybe<EOrderStatus>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['UUID']['output'];
  userName?: Maybe<Scalars['String']['output']>;
  userRole?: Maybe<EUserRole>;
};

export type OrderSortInput = {
  amount?: InputMaybe<SortEnumType>;
  comment?: InputMaybe<SortEnumType>;
  company?: InputMaybe<CompanySortInput>;
  companyId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  dealDate?: InputMaybe<SortEnumType>;
  edi?: InputMaybe<SortEnumType>;
  ediId?: InputMaybe<SortEnumType>;
  ediName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  insurance?: InputMaybe<SortEnumType>;
  logistics?: InputMaybe<SortEnumType>;
  managerUser?: InputMaybe<UserSortInput>;
  managerUserId?: InputMaybe<SortEnumType>;
  orderNumber?: InputMaybe<SortEnumType>;
  paperContractSigned?: InputMaybe<SortEnumType>;
  receiptAmount?: InputMaybe<SortEnumType>;
  receiptDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  statusComment?: InputMaybe<SortEnumType>;
  tnVed?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  user?: InputMaybe<UserSortInput>;
  userId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Order>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Order;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  company: Company;
  currentUser: User;
  myCompanies: Array<Company>;
  order?: Maybe<Order>;
  orderDocuments: Array<FileAttachment>;
  orderHistory: Array<OrderHistory>;
  orderMessages: Array<OrderMessage>;
  orders?: Maybe<OrdersConnection>;
  user: User;
  userOrders: Array<Order>;
  users?: Maybe<UsersConnection>;
};


export type QueryCompanyArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryOrderDocumentsArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryOrderHistoryArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryOrderMessagesArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<OrderSortInput>>;
  where?: InputMaybe<OrderFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUserOrdersArgs = {
  companyId?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  role: EUserRole;
  telegramUsername?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  phone?: InputMaybe<StringOperationFilterInput>;
  role?: InputMaybe<EUserRoleOperationFilterInput>;
  telegramUsername?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<DateTimeOperationFilterInput>;
};

export type UserSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  role?: InputMaybe<SortEnumType>;
  telegramUsername?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type LoginMutationVariables = Exact<{
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'User', id: any, name: string, email: string, phone: string, role: EUserRole } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'BaseResponse', status: EResponseStatus } };

export type StartRegistrationMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}>;


export type StartRegistrationMutation = { __typename?: 'Mutation', startRegistration: { __typename?: 'BaseResponse', status: EResponseStatus } };

export type ConfirmRegistrationMutationVariables = Exact<{
  code: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}>;


export type ConfirmRegistrationMutation = { __typename?: 'Mutation', confirmRegistration: { __typename?: 'User', id: any, name: string, email: string, phone: string, role: EUserRole } };

export type CompanyFragment = { __typename?: 'Company', id: any, name?: string | null, inn?: string | null, kpp?: string | null, legalAddress?: string | null, actualAddress?: string | null, ceo?: string | null, bic?: string | null, bank?: string | null, correspondentAccount?: string | null, checkingAccount?: string | null };

export type CreateCompanyMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  kpp?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  actualAddress?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  correspondentAccount?: InputMaybe<Scalars['String']['input']>;
  checkingAccount?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', saveCompany: { __typename?: 'Company', id: any, name?: string | null, inn?: string | null, kpp?: string | null, legalAddress?: string | null, actualAddress?: string | null, ceo?: string | null, bic?: string | null, bank?: string | null, correspondentAccount?: string | null, checkingAccount?: string | null } };

export type SaveCompanyMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  kpp?: InputMaybe<Scalars['String']['input']>;
  legalAddress?: InputMaybe<Scalars['String']['input']>;
  actualAddress?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  bank?: InputMaybe<Scalars['String']['input']>;
  correspondentAccount?: InputMaybe<Scalars['String']['input']>;
  checkingAccount?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveCompanyMutation = { __typename?: 'Mutation', saveCompany: { __typename?: 'Company', id: any, name?: string | null, inn?: string | null, kpp?: string | null, legalAddress?: string | null, actualAddress?: string | null, ceo?: string | null, bic?: string | null, bank?: string | null, correspondentAccount?: string | null, checkingAccount?: string | null } };

export type MyCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCompaniesQuery = { __typename?: 'Query', myCompanies: Array<{ __typename?: 'Company', id: any, name?: string | null, inn?: string | null, kpp?: string | null, legalAddress?: string | null, actualAddress?: string | null, ceo?: string | null, bic?: string | null, bank?: string | null, correspondentAccount?: string | null, checkingAccount?: string | null }> };

export type CompanyQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: any, name?: string | null, inn?: string | null, kpp?: string | null, legalAddress?: string | null, actualAddress?: string | null, ceo?: string | null, bic?: string | null, bank?: string | null, correspondentAccount?: string | null, checkingAccount?: string | null } };

export type ItemOrderFragment = { __typename?: 'Order', id: any, status: EOrderStatus, createdAt: any, updatedAt: any, orderNumber: number, company: { __typename?: 'Company', id: any, name?: string | null } };

export type ItemUserOrderFragment = { __typename?: 'Order', id: any, status: EOrderStatus, createdAt: any, updatedAt: any, orderNumber: number, company: { __typename?: 'Company', id: any, name?: string | null } };

export type OrderFragment = { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null, managerUser?: { __typename?: 'User', id: any, name: string, telegramUsername?: string | null } | null };

export type OrderDocumentFragment = { __typename?: 'FileAttachment', id: any, name?: string | null, contentType: string, documentType: EDocumentType, size: any, fileName: string, createdAt?: any | null };

export type OrderFieldsFragment = { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null };

export type OrderMessageFragment = { __typename?: 'OrderMessage', id: any, createdAt: any, message?: string | null, messageType: EMessageType, userName?: string | null, userRole?: EUserRole | null, fileAttachmentId?: any | null, orderStatus?: EOrderStatus | null, comment?: string | null, documentType?: EDocumentType | null, fileName?: string | null };

export type OrdersQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders?: { __typename?: 'OrdersConnection', edges?: Array<{ __typename?: 'OrdersEdge', node: { __typename?: 'Order', id: any, status: EOrderStatus, createdAt: any, updatedAt: any, orderNumber: number, company: { __typename?: 'Company', id: any, name?: string | null } } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type UserOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type UserOrdersQuery = { __typename?: 'Query', userOrders: Array<{ __typename?: 'Order', id: any, status: EOrderStatus, createdAt: any, updatedAt: any, orderNumber: number, company: { __typename?: 'Company', id: any, name?: string | null } }> };

export type OrderQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null, managerUser?: { __typename?: 'User', id: any, name: string, telegramUsername?: string | null } | null } | null };

export type OrderCompanyQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type OrderCompanyQuery = { __typename?: 'Query', order?: { __typename?: 'Order', company: { __typename?: 'Company', id: any, name?: string | null, inn?: string | null, kpp?: string | null, legalAddress?: string | null, actualAddress?: string | null, ceo?: string | null, bic?: string | null, bank?: string | null, correspondentAccount?: string | null, checkingAccount?: string | null } } | null };

export type OrderDocumentsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type OrderDocumentsQuery = { __typename?: 'Query', orderDocuments: Array<{ __typename?: 'FileAttachment', id: any, name?: string | null, contentType: string, documentType: EDocumentType, size: any, fileName: string, createdAt?: any | null }> };

export type CreateOrderMutationVariables = Exact<{
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  tnVed?: InputMaybe<Scalars['String']['input']>;
  logistics?: InputMaybe<Scalars['Boolean']['input']>;
  insurance?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', saveOrder: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type SaveOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  tnVed?: InputMaybe<Scalars['String']['input']>;
  logistics?: InputMaybe<Scalars['Boolean']['input']>;
  insurance?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveOrderMutation = { __typename?: 'Mutation', saveOrder: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserPublishOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserPublishOrderMutation = { __typename?: 'Mutation', userPublishOrder: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type OrderSetManagerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  managerUserId: Scalars['UUID']['input'];
}>;


export type OrderSetManagerMutation = { __typename?: 'Mutation', orderSetManager: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerSendOrderProposalMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerSendOrderProposalMutation = { __typename?: 'Mutation', managerSendOrderProposal: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserDeclineOrderProposalMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type UserDeclineOrderProposalMutation = { __typename?: 'Mutation', userDeclineOrderProposal: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserAcceptOrderProposalMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserAcceptOrderProposalMutation = { __typename?: 'Mutation', userAcceptOrderProposal: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerSendOrderContractMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerSendOrderContractMutation = { __typename?: 'Mutation', managerSendOrderContract: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserSetNeedContractCorrectionMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type UserSetNeedContractCorrectionMutation = { __typename?: 'Mutation', userSetNeedContractCorrection: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserSelectSigningMethodMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  edi: Scalars['Boolean']['input'];
  ediName?: InputMaybe<Scalars['String']['input']>;
  ediId?: InputMaybe<Scalars['String']['input']>;
  needPayerApproving: Scalars['Boolean']['input'];
}>;


export type UserSelectSigningMethodMutation = { __typename?: 'Mutation', userSelectSigningMethod: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerEdiContractUploadedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type ManagerEdiContractUploadedMutation = { __typename?: 'Mutation', managerEdiContractUploaded: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserContractSignedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserContractSignedMutation = { __typename?: 'Mutation', userContractSigned: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerOfferPayerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerOfferPayerMutation = { __typename?: 'Mutation', managerOfferPayer: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserApprovePayerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserApprovePayerMutation = { __typename?: 'Mutation', userApprovePayer: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserDeclinePayerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type UserDeclinePayerMutation = { __typename?: 'Mutation', userDeclinePayer: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerRequestDocumentsMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerRequestDocumentsMutation = { __typename?: 'Mutation', managerRequestDocuments: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserRequestedDocumentsUploadedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserRequestedDocumentsUploadedMutation = { __typename?: 'Mutation', userRequestedDocumentsUploaded: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerNeedDocumentsCorrectionMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerNeedDocumentsCorrectionMutation = { __typename?: 'Mutation', managerNeedDocumentsCorrection: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerSetDealDateMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  dealDate: Scalars['DateTime']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerSetDealDateMutation = { __typename?: 'Mutation', managerSetDealDate: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserSetNeedDealCorrectionMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type UserSetNeedDealCorrectionMutation = { __typename?: 'Mutation', userSetNeedDealCorrection: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserAcceptDealMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserAcceptDealMutation = { __typename?: 'Mutation', userAcceptDeal: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerOfferRateMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerOfferRateMutation = { __typename?: 'Mutation', managerOfferRate: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserAcceptRateMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserAcceptRateMutation = { __typename?: 'Mutation', userAcceptRate: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserDeclineRateMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type UserDeclineRateMutation = { __typename?: 'Mutation', userDeclineRate: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerInvoiceUploadedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
  amount: Scalars['Decimal']['input'];
}>;


export type ManagerInvoiceUploadedMutation = { __typename?: 'Mutation', managerInvoiceUploaded: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserInvoicePaidMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserInvoicePaidMutation = { __typename?: 'Mutation', userInvoicePaid: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerInvoicePaymentConfirmedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
  receiptAmount: Scalars['Decimal']['input'];
  paymentDate: Scalars['DateTime']['input'];
}>;


export type ManagerInvoicePaymentConfirmedMutation = { __typename?: 'Mutation', managerInvoicePaymentConfirmed: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerPaymentToSupplierConfirmedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type ManagerPaymentToSupplierConfirmedMutation = { __typename?: 'Mutation', managerPaymentToSupplierConfirmed: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserPaymentToSupplierFailedMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  comment: Scalars['String']['input'];
}>;


export type UserPaymentToSupplierFailedMutation = { __typename?: 'Mutation', userPaymentToSupplierFailed: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type UserActSentMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserActSentMutation = { __typename?: 'Mutation', userActSent: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type ManagerFinishOrderMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type ManagerFinishOrderMutation = { __typename?: 'Mutation', managerFinishOrder: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type SetOrderStatusMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  status: EOrderStatus;
  comment: Scalars['String']['input'];
}>;


export type SetOrderStatusMutation = { __typename?: 'Mutation', adminSetOrderStatus: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type RemoveDocumentMutationVariables = Exact<{
  orderId: Scalars['UUID']['input'];
  documentId: Scalars['UUID']['input'];
}>;


export type RemoveDocumentMutation = { __typename?: 'Mutation', managerRemoveDocument: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type SetStatusMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  status: EOrderStatus;
  comment: Scalars['String']['input'];
}>;


export type SetStatusMutation = { __typename?: 'Mutation', adminSetOrderStatus: { __typename?: 'Order', id: any, amount?: any | null, comment?: string | null, createdAt: any, status: EOrderStatus, updatedAt: any, edi: boolean, orderNumber: number, userId: any, ediId?: string | null, ediName?: string | null, insurance: boolean, paperContractSigned: boolean, companyId: any, logistics: boolean, receiptAmount?: any | null, receiptDate?: any | null, statusComment?: string | null, tnVed?: string | null, dealDate?: any | null, managerUserId?: any | null } };

export type OrderMessagesQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type OrderMessagesQuery = { __typename?: 'Query', orderMessages: Array<{ __typename?: 'OrderMessage', id: any, createdAt: any, message?: string | null, messageType: EMessageType, userName?: string | null, userRole?: EUserRole | null, fileAttachmentId?: any | null, orderStatus?: EOrderStatus | null, comment?: string | null, documentType?: EDocumentType | null, fileName?: string | null }> };

export type AddOrderTextMessageMutationVariables = Exact<{
  orderId: Scalars['UUID']['input'];
  message: Scalars['String']['input'];
}>;


export type AddOrderTextMessageMutation = { __typename?: 'Mutation', addOrderTextMessage: { __typename?: 'BaseResponse', status: EResponseStatus } };

export type CurrentUserFragment = { __typename?: 'User', id: any, name: string, email: string, telegramUsername?: string | null, phone: string, role: EUserRole };

export type ItemUserFragment = { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any };

export type UserFragment = { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: any, name: string, email: string, telegramUsername?: string | null, phone: string, role: EUserRole } };

export type UsersQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users?: { __typename?: 'UsersConnection', edges?: Array<{ __typename?: 'UsersEdge', node: { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } | null };

export type ManagersQueryVariables = Exact<{ [key: string]: never; }>;


export type ManagersQuery = { __typename?: 'Query', users?: { __typename?: 'UsersConnection', edges?: Array<{ __typename?: 'UsersEdge', node: { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any } }> | null } | null };

export type UserQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any } };

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  role: EUserRole;
  telegramUsername?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', saveUser: { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any } };

export type SaveUserMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  role: EUserRole;
  telegramUsername?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveUserMutation = { __typename?: 'Mutation', saveUser: { __typename?: 'User', id: any, email: string, name: string, phone: string, telegramUsername?: string | null, role: EUserRole, createdAt: any, updatedAt: any } };

export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name
  inn
  kpp
  legalAddress
  actualAddress
  ceo
  bic
  bank
  correspondentAccount
  checkingAccount
}
    `;
export const ItemOrderFragmentDoc = gql`
    fragment ItemOrder on Order {
  id
  status
  createdAt
  updatedAt
  orderNumber
  company {
    id
    name
  }
}
    `;
export const ItemUserOrderFragmentDoc = gql`
    fragment ItemUserOrder on Order {
  id
  status
  createdAt
  updatedAt
  orderNumber
  company {
    id
    name
  }
}
    `;
export const OrderFieldsFragmentDoc = gql`
    fragment OrderFields on Order {
  id
  amount
  comment
  createdAt
  status
  updatedAt
  edi
  orderNumber
  userId
  ediId
  ediName
  insurance
  paperContractSigned
  companyId
  logistics
  receiptAmount
  receiptDate
  status
  statusComment
  tnVed
  dealDate
  receiptAmount
  receiptDate
  managerUserId
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  ...OrderFields
  managerUser {
    id
    name
    telegramUsername
  }
}
    ${OrderFieldsFragmentDoc}`;
export const OrderDocumentFragmentDoc = gql`
    fragment OrderDocument on FileAttachment {
  id
  name
  contentType
  documentType
  size
  fileName
  createdAt
}
    `;
export const OrderMessageFragmentDoc = gql`
    fragment OrderMessage on OrderMessage {
  id
  createdAt
  message
  messageType
  userName
  userRole
  fileAttachmentId
  orderStatus
  comment
  documentType
  fileName
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  name
  email
  telegramUsername
  phone
  role
}
    `;
export const ItemUserFragmentDoc = gql`
    fragment ItemUser on User {
  id
  email
  name
  phone
  telegramUsername
  role
  createdAt
  updatedAt
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  name
  phone
  telegramUsername
  role
  createdAt
  updatedAt
}
    `;
export const LoginDocument = gql`
    mutation Login($password: String!, $phone: String!) {
  login(password: $password, phone: $phone) {
    id
    name
    email
    phone
    role
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const StartRegistrationDocument = gql`
    mutation StartRegistration($email: String!, $name: String!, $password: String!, $phone: String!) {
  startRegistration(
    email: $email
    name: $name
    password: $password
    phone: $phone
  ) {
    status
  }
}
    `;
export type StartRegistrationMutationFn = Apollo.MutationFunction<StartRegistrationMutation, StartRegistrationMutationVariables>;

/**
 * __useStartRegistrationMutation__
 *
 * To run a mutation, you first call `useStartRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startRegistrationMutation, { data, loading, error }] = useStartRegistrationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useStartRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<StartRegistrationMutation, StartRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartRegistrationMutation, StartRegistrationMutationVariables>(StartRegistrationDocument, options);
      }
export type StartRegistrationMutationHookResult = ReturnType<typeof useStartRegistrationMutation>;
export type StartRegistrationMutationResult = Apollo.MutationResult<StartRegistrationMutation>;
export type StartRegistrationMutationOptions = Apollo.BaseMutationOptions<StartRegistrationMutation, StartRegistrationMutationVariables>;
export const ConfirmRegistrationDocument = gql`
    mutation ConfirmRegistration($code: String!, $phone: String!) {
  confirmRegistration(code: $code, phone: $phone) {
    id
    name
    email
    phone
    role
  }
}
    `;
export type ConfirmRegistrationMutationFn = Apollo.MutationFunction<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>;

/**
 * __useConfirmRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRegistrationMutation, { data, loading, error }] = useConfirmRegistrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useConfirmRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>(ConfirmRegistrationDocument, options);
      }
export type ConfirmRegistrationMutationHookResult = ReturnType<typeof useConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationResult = Apollo.MutationResult<ConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationOptions = Apollo.BaseMutationOptions<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($name: String, $inn: String, $kpp: String, $legalAddress: String, $actualAddress: String, $ceo: String, $bic: String, $bank: String, $correspondentAccount: String, $checkingAccount: String) {
  saveCompany(
    name: $name
    inn: $inn
    kpp: $kpp
    legalAddress: $legalAddress
    actualAddress: $actualAddress
    ceo: $ceo
    bic: $bic
    bank: $bank
    correspondentAccount: $correspondentAccount
    checkingAccount: $checkingAccount
  ) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      inn: // value for 'inn'
 *      kpp: // value for 'kpp'
 *      legalAddress: // value for 'legalAddress'
 *      actualAddress: // value for 'actualAddress'
 *      ceo: // value for 'ceo'
 *      bic: // value for 'bic'
 *      bank: // value for 'bank'
 *      correspondentAccount: // value for 'correspondentAccount'
 *      checkingAccount: // value for 'checkingAccount'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const SaveCompanyDocument = gql`
    mutation SaveCompany($id: UUID!, $name: String, $inn: String, $kpp: String, $legalAddress: String, $actualAddress: String, $ceo: String, $bic: String, $bank: String, $correspondentAccount: String, $checkingAccount: String) {
  saveCompany(
    id: $id
    name: $name
    inn: $inn
    kpp: $kpp
    legalAddress: $legalAddress
    actualAddress: $actualAddress
    ceo: $ceo
    bic: $bic
    bank: $bank
    correspondentAccount: $correspondentAccount
    checkingAccount: $checkingAccount
  ) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export type SaveCompanyMutationFn = Apollo.MutationFunction<SaveCompanyMutation, SaveCompanyMutationVariables>;

/**
 * __useSaveCompanyMutation__
 *
 * To run a mutation, you first call `useSaveCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCompanyMutation, { data, loading, error }] = useSaveCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      inn: // value for 'inn'
 *      kpp: // value for 'kpp'
 *      legalAddress: // value for 'legalAddress'
 *      actualAddress: // value for 'actualAddress'
 *      ceo: // value for 'ceo'
 *      bic: // value for 'bic'
 *      bank: // value for 'bank'
 *      correspondentAccount: // value for 'correspondentAccount'
 *      checkingAccount: // value for 'checkingAccount'
 *   },
 * });
 */
export function useSaveCompanyMutation(baseOptions?: Apollo.MutationHookOptions<SaveCompanyMutation, SaveCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCompanyMutation, SaveCompanyMutationVariables>(SaveCompanyDocument, options);
      }
export type SaveCompanyMutationHookResult = ReturnType<typeof useSaveCompanyMutation>;
export type SaveCompanyMutationResult = Apollo.MutationResult<SaveCompanyMutation>;
export type SaveCompanyMutationOptions = Apollo.BaseMutationOptions<SaveCompanyMutation, SaveCompanyMutationVariables>;
export const MyCompaniesDocument = gql`
    query myCompanies {
  myCompanies {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useMyCompaniesQuery__
 *
 * To run a query within a React component, call `useMyCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<MyCompaniesQuery, MyCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCompaniesQuery, MyCompaniesQueryVariables>(MyCompaniesDocument, options);
      }
export function useMyCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCompaniesQuery, MyCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCompaniesQuery, MyCompaniesQueryVariables>(MyCompaniesDocument, options);
        }
export function useMyCompaniesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyCompaniesQuery, MyCompaniesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyCompaniesQuery, MyCompaniesQueryVariables>(MyCompaniesDocument, options);
        }
export type MyCompaniesQueryHookResult = ReturnType<typeof useMyCompaniesQuery>;
export type MyCompaniesLazyQueryHookResult = ReturnType<typeof useMyCompaniesLazyQuery>;
export type MyCompaniesSuspenseQueryHookResult = ReturnType<typeof useMyCompaniesSuspenseQuery>;
export type MyCompaniesQueryResult = Apollo.QueryResult<MyCompaniesQuery, MyCompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: UUID!) {
  company(id: $id) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables> & ({ variables: CompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export function useCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanySuspenseQueryHookResult = ReturnType<typeof useCompanySuspenseQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const OrdersDocument = gql`
    query Orders($cursor: String) {
  orders(first: 30, after: $cursor, order: {updatedAt: DESC}) {
    edges {
      node {
        ...ItemOrder
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ItemOrderFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export function useOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersSuspenseQueryHookResult = ReturnType<typeof useOrdersSuspenseQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const UserOrdersDocument = gql`
    query UserOrders {
  userOrders {
    ...ItemUserOrder
  }
}
    ${ItemUserOrderFragmentDoc}`;

/**
 * __useUserOrdersQuery__
 *
 * To run a query within a React component, call `useUserOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrdersQuery(baseOptions?: Apollo.QueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersDocument, options);
      }
export function useUserOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersDocument, options);
        }
export function useUserOrdersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserOrdersQuery, UserOrdersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserOrdersQuery, UserOrdersQueryVariables>(UserOrdersDocument, options);
        }
export type UserOrdersQueryHookResult = ReturnType<typeof useUserOrdersQuery>;
export type UserOrdersLazyQueryHookResult = ReturnType<typeof useUserOrdersLazyQuery>;
export type UserOrdersSuspenseQueryHookResult = ReturnType<typeof useUserOrdersSuspenseQuery>;
export type UserOrdersQueryResult = Apollo.QueryResult<UserOrdersQuery, UserOrdersQueryVariables>;
export const OrderDocument = gql`
    query Order($id: UUID!) {
  order(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables> & ({ variables: OrderQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export function useOrderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderSuspenseQueryHookResult = ReturnType<typeof useOrderSuspenseQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderCompanyDocument = gql`
    query OrderCompany($id: UUID!) {
  order(id: $id) {
    company {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useOrderCompanyQuery__
 *
 * To run a query within a React component, call `useOrderCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderCompanyQuery(baseOptions: Apollo.QueryHookOptions<OrderCompanyQuery, OrderCompanyQueryVariables> & ({ variables: OrderCompanyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderCompanyQuery, OrderCompanyQueryVariables>(OrderCompanyDocument, options);
      }
export function useOrderCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderCompanyQuery, OrderCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderCompanyQuery, OrderCompanyQueryVariables>(OrderCompanyDocument, options);
        }
export function useOrderCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderCompanyQuery, OrderCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderCompanyQuery, OrderCompanyQueryVariables>(OrderCompanyDocument, options);
        }
export type OrderCompanyQueryHookResult = ReturnType<typeof useOrderCompanyQuery>;
export type OrderCompanyLazyQueryHookResult = ReturnType<typeof useOrderCompanyLazyQuery>;
export type OrderCompanySuspenseQueryHookResult = ReturnType<typeof useOrderCompanySuspenseQuery>;
export type OrderCompanyQueryResult = Apollo.QueryResult<OrderCompanyQuery, OrderCompanyQueryVariables>;
export const OrderDocumentsDocument = gql`
    query OrderDocuments($id: UUID!) {
  orderDocuments(id: $id) {
    ...OrderDocument
  }
}
    ${OrderDocumentFragmentDoc}`;

/**
 * __useOrderDocumentsQuery__
 *
 * To run a query within a React component, call `useOrderDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderDocumentsQuery(baseOptions: Apollo.QueryHookOptions<OrderDocumentsQuery, OrderDocumentsQueryVariables> & ({ variables: OrderDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDocumentsQuery, OrderDocumentsQueryVariables>(OrderDocumentsDocument, options);
      }
export function useOrderDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDocumentsQuery, OrderDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDocumentsQuery, OrderDocumentsQueryVariables>(OrderDocumentsDocument, options);
        }
export function useOrderDocumentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderDocumentsQuery, OrderDocumentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderDocumentsQuery, OrderDocumentsQueryVariables>(OrderDocumentsDocument, options);
        }
export type OrderDocumentsQueryHookResult = ReturnType<typeof useOrderDocumentsQuery>;
export type OrderDocumentsLazyQueryHookResult = ReturnType<typeof useOrderDocumentsLazyQuery>;
export type OrderDocumentsSuspenseQueryHookResult = ReturnType<typeof useOrderDocumentsSuspenseQuery>;
export type OrderDocumentsQueryResult = Apollo.QueryResult<OrderDocumentsQuery, OrderDocumentsQueryVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($companyId: UUID, $tnVed: String, $logistics: Boolean, $insurance: Boolean, $comment: String) {
  saveOrder(
    companyId: $companyId
    tnVed: $tnVed
    logistics: $logistics
    insurance: $insurance
    comment: $comment
  ) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      tnVed: // value for 'tnVed'
 *      logistics: // value for 'logistics'
 *      insurance: // value for 'insurance'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const SaveOrderDocument = gql`
    mutation SaveOrder($id: UUID!, $companyId: UUID, $tnVed: String, $logistics: Boolean, $insurance: Boolean, $comment: String) {
  saveOrder(
    id: $id
    companyId: $companyId
    tnVed: $tnVed
    logistics: $logistics
    insurance: $insurance
    comment: $comment
  ) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type SaveOrderMutationFn = Apollo.MutationFunction<SaveOrderMutation, SaveOrderMutationVariables>;

/**
 * __useSaveOrderMutation__
 *
 * To run a mutation, you first call `useSaveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrderMutation, { data, loading, error }] = useSaveOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      tnVed: // value for 'tnVed'
 *      logistics: // value for 'logistics'
 *      insurance: // value for 'insurance'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSaveOrderMutation(baseOptions?: Apollo.MutationHookOptions<SaveOrderMutation, SaveOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOrderMutation, SaveOrderMutationVariables>(SaveOrderDocument, options);
      }
export type SaveOrderMutationHookResult = ReturnType<typeof useSaveOrderMutation>;
export type SaveOrderMutationResult = Apollo.MutationResult<SaveOrderMutation>;
export type SaveOrderMutationOptions = Apollo.BaseMutationOptions<SaveOrderMutation, SaveOrderMutationVariables>;
export const UserPublishOrderDocument = gql`
    mutation UserPublishOrder($id: UUID!) {
  userPublishOrder(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserPublishOrderMutationFn = Apollo.MutationFunction<UserPublishOrderMutation, UserPublishOrderMutationVariables>;

/**
 * __useUserPublishOrderMutation__
 *
 * To run a mutation, you first call `useUserPublishOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPublishOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPublishOrderMutation, { data, loading, error }] = useUserPublishOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPublishOrderMutation(baseOptions?: Apollo.MutationHookOptions<UserPublishOrderMutation, UserPublishOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPublishOrderMutation, UserPublishOrderMutationVariables>(UserPublishOrderDocument, options);
      }
export type UserPublishOrderMutationHookResult = ReturnType<typeof useUserPublishOrderMutation>;
export type UserPublishOrderMutationResult = Apollo.MutationResult<UserPublishOrderMutation>;
export type UserPublishOrderMutationOptions = Apollo.BaseMutationOptions<UserPublishOrderMutation, UserPublishOrderMutationVariables>;
export const OrderSetManagerDocument = gql`
    mutation OrderSetManager($id: UUID!, $managerUserId: UUID!) {
  orderSetManager(id: $id, managerUserId: $managerUserId) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type OrderSetManagerMutationFn = Apollo.MutationFunction<OrderSetManagerMutation, OrderSetManagerMutationVariables>;

/**
 * __useOrderSetManagerMutation__
 *
 * To run a mutation, you first call `useOrderSetManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderSetManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderSetManagerMutation, { data, loading, error }] = useOrderSetManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      managerUserId: // value for 'managerUserId'
 *   },
 * });
 */
export function useOrderSetManagerMutation(baseOptions?: Apollo.MutationHookOptions<OrderSetManagerMutation, OrderSetManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderSetManagerMutation, OrderSetManagerMutationVariables>(OrderSetManagerDocument, options);
      }
export type OrderSetManagerMutationHookResult = ReturnType<typeof useOrderSetManagerMutation>;
export type OrderSetManagerMutationResult = Apollo.MutationResult<OrderSetManagerMutation>;
export type OrderSetManagerMutationOptions = Apollo.BaseMutationOptions<OrderSetManagerMutation, OrderSetManagerMutationVariables>;
export const ManagerSendOrderProposalDocument = gql`
    mutation ManagerSendOrderProposal($id: UUID!, $comment: String!) {
  managerSendOrderProposal(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerSendOrderProposalMutationFn = Apollo.MutationFunction<ManagerSendOrderProposalMutation, ManagerSendOrderProposalMutationVariables>;

/**
 * __useManagerSendOrderProposalMutation__
 *
 * To run a mutation, you first call `useManagerSendOrderProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerSendOrderProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerSendOrderProposalMutation, { data, loading, error }] = useManagerSendOrderProposalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerSendOrderProposalMutation(baseOptions?: Apollo.MutationHookOptions<ManagerSendOrderProposalMutation, ManagerSendOrderProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerSendOrderProposalMutation, ManagerSendOrderProposalMutationVariables>(ManagerSendOrderProposalDocument, options);
      }
export type ManagerSendOrderProposalMutationHookResult = ReturnType<typeof useManagerSendOrderProposalMutation>;
export type ManagerSendOrderProposalMutationResult = Apollo.MutationResult<ManagerSendOrderProposalMutation>;
export type ManagerSendOrderProposalMutationOptions = Apollo.BaseMutationOptions<ManagerSendOrderProposalMutation, ManagerSendOrderProposalMutationVariables>;
export const UserDeclineOrderProposalDocument = gql`
    mutation UserDeclineOrderProposal($id: UUID!, $comment: String!) {
  userDeclineOrderProposal(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserDeclineOrderProposalMutationFn = Apollo.MutationFunction<UserDeclineOrderProposalMutation, UserDeclineOrderProposalMutationVariables>;

/**
 * __useUserDeclineOrderProposalMutation__
 *
 * To run a mutation, you first call `useUserDeclineOrderProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeclineOrderProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeclineOrderProposalMutation, { data, loading, error }] = useUserDeclineOrderProposalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUserDeclineOrderProposalMutation(baseOptions?: Apollo.MutationHookOptions<UserDeclineOrderProposalMutation, UserDeclineOrderProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeclineOrderProposalMutation, UserDeclineOrderProposalMutationVariables>(UserDeclineOrderProposalDocument, options);
      }
export type UserDeclineOrderProposalMutationHookResult = ReturnType<typeof useUserDeclineOrderProposalMutation>;
export type UserDeclineOrderProposalMutationResult = Apollo.MutationResult<UserDeclineOrderProposalMutation>;
export type UserDeclineOrderProposalMutationOptions = Apollo.BaseMutationOptions<UserDeclineOrderProposalMutation, UserDeclineOrderProposalMutationVariables>;
export const UserAcceptOrderProposalDocument = gql`
    mutation UserAcceptOrderProposal($id: UUID!) {
  userAcceptOrderProposal(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserAcceptOrderProposalMutationFn = Apollo.MutationFunction<UserAcceptOrderProposalMutation, UserAcceptOrderProposalMutationVariables>;

/**
 * __useUserAcceptOrderProposalMutation__
 *
 * To run a mutation, you first call `useUserAcceptOrderProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAcceptOrderProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAcceptOrderProposalMutation, { data, loading, error }] = useUserAcceptOrderProposalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAcceptOrderProposalMutation(baseOptions?: Apollo.MutationHookOptions<UserAcceptOrderProposalMutation, UserAcceptOrderProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserAcceptOrderProposalMutation, UserAcceptOrderProposalMutationVariables>(UserAcceptOrderProposalDocument, options);
      }
export type UserAcceptOrderProposalMutationHookResult = ReturnType<typeof useUserAcceptOrderProposalMutation>;
export type UserAcceptOrderProposalMutationResult = Apollo.MutationResult<UserAcceptOrderProposalMutation>;
export type UserAcceptOrderProposalMutationOptions = Apollo.BaseMutationOptions<UserAcceptOrderProposalMutation, UserAcceptOrderProposalMutationVariables>;
export const ManagerSendOrderContractDocument = gql`
    mutation ManagerSendOrderContract($id: UUID!, $comment: String!) {
  managerSendOrderContract(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerSendOrderContractMutationFn = Apollo.MutationFunction<ManagerSendOrderContractMutation, ManagerSendOrderContractMutationVariables>;

/**
 * __useManagerSendOrderContractMutation__
 *
 * To run a mutation, you first call `useManagerSendOrderContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerSendOrderContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerSendOrderContractMutation, { data, loading, error }] = useManagerSendOrderContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerSendOrderContractMutation(baseOptions?: Apollo.MutationHookOptions<ManagerSendOrderContractMutation, ManagerSendOrderContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerSendOrderContractMutation, ManagerSendOrderContractMutationVariables>(ManagerSendOrderContractDocument, options);
      }
export type ManagerSendOrderContractMutationHookResult = ReturnType<typeof useManagerSendOrderContractMutation>;
export type ManagerSendOrderContractMutationResult = Apollo.MutationResult<ManagerSendOrderContractMutation>;
export type ManagerSendOrderContractMutationOptions = Apollo.BaseMutationOptions<ManagerSendOrderContractMutation, ManagerSendOrderContractMutationVariables>;
export const UserSetNeedContractCorrectionDocument = gql`
    mutation UserSetNeedContractCorrection($id: UUID!, $comment: String!) {
  userSetNeedContractCorrection(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserSetNeedContractCorrectionMutationFn = Apollo.MutationFunction<UserSetNeedContractCorrectionMutation, UserSetNeedContractCorrectionMutationVariables>;

/**
 * __useUserSetNeedContractCorrectionMutation__
 *
 * To run a mutation, you first call `useUserSetNeedContractCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetNeedContractCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetNeedContractCorrectionMutation, { data, loading, error }] = useUserSetNeedContractCorrectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUserSetNeedContractCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<UserSetNeedContractCorrectionMutation, UserSetNeedContractCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSetNeedContractCorrectionMutation, UserSetNeedContractCorrectionMutationVariables>(UserSetNeedContractCorrectionDocument, options);
      }
export type UserSetNeedContractCorrectionMutationHookResult = ReturnType<typeof useUserSetNeedContractCorrectionMutation>;
export type UserSetNeedContractCorrectionMutationResult = Apollo.MutationResult<UserSetNeedContractCorrectionMutation>;
export type UserSetNeedContractCorrectionMutationOptions = Apollo.BaseMutationOptions<UserSetNeedContractCorrectionMutation, UserSetNeedContractCorrectionMutationVariables>;
export const UserSelectSigningMethodDocument = gql`
    mutation UserSelectSigningMethod($id: UUID!, $edi: Boolean!, $ediName: String, $ediId: String, $needPayerApproving: Boolean!) {
  userSelectSigningMethod(
    id: $id
    edi: $edi
    ediName: $ediName
    ediId: $ediId
    needPayerApproving: $needPayerApproving
  ) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserSelectSigningMethodMutationFn = Apollo.MutationFunction<UserSelectSigningMethodMutation, UserSelectSigningMethodMutationVariables>;

/**
 * __useUserSelectSigningMethodMutation__
 *
 * To run a mutation, you first call `useUserSelectSigningMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSelectSigningMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSelectSigningMethodMutation, { data, loading, error }] = useUserSelectSigningMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      edi: // value for 'edi'
 *      ediName: // value for 'ediName'
 *      ediId: // value for 'ediId'
 *      needPayerApproving: // value for 'needPayerApproving'
 *   },
 * });
 */
export function useUserSelectSigningMethodMutation(baseOptions?: Apollo.MutationHookOptions<UserSelectSigningMethodMutation, UserSelectSigningMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSelectSigningMethodMutation, UserSelectSigningMethodMutationVariables>(UserSelectSigningMethodDocument, options);
      }
export type UserSelectSigningMethodMutationHookResult = ReturnType<typeof useUserSelectSigningMethodMutation>;
export type UserSelectSigningMethodMutationResult = Apollo.MutationResult<UserSelectSigningMethodMutation>;
export type UserSelectSigningMethodMutationOptions = Apollo.BaseMutationOptions<UserSelectSigningMethodMutation, UserSelectSigningMethodMutationVariables>;
export const ManagerEdiContractUploadedDocument = gql`
    mutation ManagerEdiContractUploaded($id: UUID!) {
  managerEdiContractUploaded(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerEdiContractUploadedMutationFn = Apollo.MutationFunction<ManagerEdiContractUploadedMutation, ManagerEdiContractUploadedMutationVariables>;

/**
 * __useManagerEdiContractUploadedMutation__
 *
 * To run a mutation, you first call `useManagerEdiContractUploadedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerEdiContractUploadedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerEdiContractUploadedMutation, { data, loading, error }] = useManagerEdiContractUploadedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManagerEdiContractUploadedMutation(baseOptions?: Apollo.MutationHookOptions<ManagerEdiContractUploadedMutation, ManagerEdiContractUploadedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerEdiContractUploadedMutation, ManagerEdiContractUploadedMutationVariables>(ManagerEdiContractUploadedDocument, options);
      }
export type ManagerEdiContractUploadedMutationHookResult = ReturnType<typeof useManagerEdiContractUploadedMutation>;
export type ManagerEdiContractUploadedMutationResult = Apollo.MutationResult<ManagerEdiContractUploadedMutation>;
export type ManagerEdiContractUploadedMutationOptions = Apollo.BaseMutationOptions<ManagerEdiContractUploadedMutation, ManagerEdiContractUploadedMutationVariables>;
export const UserContractSignedDocument = gql`
    mutation UserContractSigned($id: UUID!) {
  userContractSigned(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserContractSignedMutationFn = Apollo.MutationFunction<UserContractSignedMutation, UserContractSignedMutationVariables>;

/**
 * __useUserContractSignedMutation__
 *
 * To run a mutation, you first call `useUserContractSignedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserContractSignedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userContractSignedMutation, { data, loading, error }] = useUserContractSignedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserContractSignedMutation(baseOptions?: Apollo.MutationHookOptions<UserContractSignedMutation, UserContractSignedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserContractSignedMutation, UserContractSignedMutationVariables>(UserContractSignedDocument, options);
      }
export type UserContractSignedMutationHookResult = ReturnType<typeof useUserContractSignedMutation>;
export type UserContractSignedMutationResult = Apollo.MutationResult<UserContractSignedMutation>;
export type UserContractSignedMutationOptions = Apollo.BaseMutationOptions<UserContractSignedMutation, UserContractSignedMutationVariables>;
export const ManagerOfferPayerDocument = gql`
    mutation ManagerOfferPayer($id: UUID!, $comment: String!) {
  managerOfferPayer(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerOfferPayerMutationFn = Apollo.MutationFunction<ManagerOfferPayerMutation, ManagerOfferPayerMutationVariables>;

/**
 * __useManagerOfferPayerMutation__
 *
 * To run a mutation, you first call `useManagerOfferPayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerOfferPayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerOfferPayerMutation, { data, loading, error }] = useManagerOfferPayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerOfferPayerMutation(baseOptions?: Apollo.MutationHookOptions<ManagerOfferPayerMutation, ManagerOfferPayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerOfferPayerMutation, ManagerOfferPayerMutationVariables>(ManagerOfferPayerDocument, options);
      }
export type ManagerOfferPayerMutationHookResult = ReturnType<typeof useManagerOfferPayerMutation>;
export type ManagerOfferPayerMutationResult = Apollo.MutationResult<ManagerOfferPayerMutation>;
export type ManagerOfferPayerMutationOptions = Apollo.BaseMutationOptions<ManagerOfferPayerMutation, ManagerOfferPayerMutationVariables>;
export const UserApprovePayerDocument = gql`
    mutation UserApprovePayer($id: UUID!) {
  userApprovePayer(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserApprovePayerMutationFn = Apollo.MutationFunction<UserApprovePayerMutation, UserApprovePayerMutationVariables>;

/**
 * __useUserApprovePayerMutation__
 *
 * To run a mutation, you first call `useUserApprovePayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserApprovePayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userApprovePayerMutation, { data, loading, error }] = useUserApprovePayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserApprovePayerMutation(baseOptions?: Apollo.MutationHookOptions<UserApprovePayerMutation, UserApprovePayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserApprovePayerMutation, UserApprovePayerMutationVariables>(UserApprovePayerDocument, options);
      }
export type UserApprovePayerMutationHookResult = ReturnType<typeof useUserApprovePayerMutation>;
export type UserApprovePayerMutationResult = Apollo.MutationResult<UserApprovePayerMutation>;
export type UserApprovePayerMutationOptions = Apollo.BaseMutationOptions<UserApprovePayerMutation, UserApprovePayerMutationVariables>;
export const UserDeclinePayerDocument = gql`
    mutation UserDeclinePayer($id: UUID!, $comment: String!) {
  userDeclinePayer(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserDeclinePayerMutationFn = Apollo.MutationFunction<UserDeclinePayerMutation, UserDeclinePayerMutationVariables>;

/**
 * __useUserDeclinePayerMutation__
 *
 * To run a mutation, you first call `useUserDeclinePayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeclinePayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeclinePayerMutation, { data, loading, error }] = useUserDeclinePayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUserDeclinePayerMutation(baseOptions?: Apollo.MutationHookOptions<UserDeclinePayerMutation, UserDeclinePayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeclinePayerMutation, UserDeclinePayerMutationVariables>(UserDeclinePayerDocument, options);
      }
export type UserDeclinePayerMutationHookResult = ReturnType<typeof useUserDeclinePayerMutation>;
export type UserDeclinePayerMutationResult = Apollo.MutationResult<UserDeclinePayerMutation>;
export type UserDeclinePayerMutationOptions = Apollo.BaseMutationOptions<UserDeclinePayerMutation, UserDeclinePayerMutationVariables>;
export const ManagerRequestDocumentsDocument = gql`
    mutation ManagerRequestDocuments($id: UUID!, $comment: String!) {
  managerRequestDocuments(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerRequestDocumentsMutationFn = Apollo.MutationFunction<ManagerRequestDocumentsMutation, ManagerRequestDocumentsMutationVariables>;

/**
 * __useManagerRequestDocumentsMutation__
 *
 * To run a mutation, you first call `useManagerRequestDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerRequestDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerRequestDocumentsMutation, { data, loading, error }] = useManagerRequestDocumentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerRequestDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<ManagerRequestDocumentsMutation, ManagerRequestDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerRequestDocumentsMutation, ManagerRequestDocumentsMutationVariables>(ManagerRequestDocumentsDocument, options);
      }
export type ManagerRequestDocumentsMutationHookResult = ReturnType<typeof useManagerRequestDocumentsMutation>;
export type ManagerRequestDocumentsMutationResult = Apollo.MutationResult<ManagerRequestDocumentsMutation>;
export type ManagerRequestDocumentsMutationOptions = Apollo.BaseMutationOptions<ManagerRequestDocumentsMutation, ManagerRequestDocumentsMutationVariables>;
export const UserRequestedDocumentsUploadedDocument = gql`
    mutation UserRequestedDocumentsUploaded($id: UUID!) {
  userRequestedDocumentsUploaded(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserRequestedDocumentsUploadedMutationFn = Apollo.MutationFunction<UserRequestedDocumentsUploadedMutation, UserRequestedDocumentsUploadedMutationVariables>;

/**
 * __useUserRequestedDocumentsUploadedMutation__
 *
 * To run a mutation, you first call `useUserRequestedDocumentsUploadedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRequestedDocumentsUploadedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRequestedDocumentsUploadedMutation, { data, loading, error }] = useUserRequestedDocumentsUploadedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRequestedDocumentsUploadedMutation(baseOptions?: Apollo.MutationHookOptions<UserRequestedDocumentsUploadedMutation, UserRequestedDocumentsUploadedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRequestedDocumentsUploadedMutation, UserRequestedDocumentsUploadedMutationVariables>(UserRequestedDocumentsUploadedDocument, options);
      }
export type UserRequestedDocumentsUploadedMutationHookResult = ReturnType<typeof useUserRequestedDocumentsUploadedMutation>;
export type UserRequestedDocumentsUploadedMutationResult = Apollo.MutationResult<UserRequestedDocumentsUploadedMutation>;
export type UserRequestedDocumentsUploadedMutationOptions = Apollo.BaseMutationOptions<UserRequestedDocumentsUploadedMutation, UserRequestedDocumentsUploadedMutationVariables>;
export const ManagerNeedDocumentsCorrectionDocument = gql`
    mutation ManagerNeedDocumentsCorrection($id: UUID!, $comment: String!) {
  managerNeedDocumentsCorrection(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerNeedDocumentsCorrectionMutationFn = Apollo.MutationFunction<ManagerNeedDocumentsCorrectionMutation, ManagerNeedDocumentsCorrectionMutationVariables>;

/**
 * __useManagerNeedDocumentsCorrectionMutation__
 *
 * To run a mutation, you first call `useManagerNeedDocumentsCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerNeedDocumentsCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerNeedDocumentsCorrectionMutation, { data, loading, error }] = useManagerNeedDocumentsCorrectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerNeedDocumentsCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<ManagerNeedDocumentsCorrectionMutation, ManagerNeedDocumentsCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerNeedDocumentsCorrectionMutation, ManagerNeedDocumentsCorrectionMutationVariables>(ManagerNeedDocumentsCorrectionDocument, options);
      }
export type ManagerNeedDocumentsCorrectionMutationHookResult = ReturnType<typeof useManagerNeedDocumentsCorrectionMutation>;
export type ManagerNeedDocumentsCorrectionMutationResult = Apollo.MutationResult<ManagerNeedDocumentsCorrectionMutation>;
export type ManagerNeedDocumentsCorrectionMutationOptions = Apollo.BaseMutationOptions<ManagerNeedDocumentsCorrectionMutation, ManagerNeedDocumentsCorrectionMutationVariables>;
export const ManagerSetDealDateDocument = gql`
    mutation ManagerSetDealDate($id: UUID!, $dealDate: DateTime!, $comment: String!) {
  managerSetDealDate(id: $id, dealDate: $dealDate, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerSetDealDateMutationFn = Apollo.MutationFunction<ManagerSetDealDateMutation, ManagerSetDealDateMutationVariables>;

/**
 * __useManagerSetDealDateMutation__
 *
 * To run a mutation, you first call `useManagerSetDealDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerSetDealDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerSetDealDateMutation, { data, loading, error }] = useManagerSetDealDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dealDate: // value for 'dealDate'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerSetDealDateMutation(baseOptions?: Apollo.MutationHookOptions<ManagerSetDealDateMutation, ManagerSetDealDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerSetDealDateMutation, ManagerSetDealDateMutationVariables>(ManagerSetDealDateDocument, options);
      }
export type ManagerSetDealDateMutationHookResult = ReturnType<typeof useManagerSetDealDateMutation>;
export type ManagerSetDealDateMutationResult = Apollo.MutationResult<ManagerSetDealDateMutation>;
export type ManagerSetDealDateMutationOptions = Apollo.BaseMutationOptions<ManagerSetDealDateMutation, ManagerSetDealDateMutationVariables>;
export const UserSetNeedDealCorrectionDocument = gql`
    mutation UserSetNeedDealCorrection($id: UUID!, $comment: String!) {
  userSetNeedDealCorrection(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserSetNeedDealCorrectionMutationFn = Apollo.MutationFunction<UserSetNeedDealCorrectionMutation, UserSetNeedDealCorrectionMutationVariables>;

/**
 * __useUserSetNeedDealCorrectionMutation__
 *
 * To run a mutation, you first call `useUserSetNeedDealCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetNeedDealCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetNeedDealCorrectionMutation, { data, loading, error }] = useUserSetNeedDealCorrectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUserSetNeedDealCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<UserSetNeedDealCorrectionMutation, UserSetNeedDealCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSetNeedDealCorrectionMutation, UserSetNeedDealCorrectionMutationVariables>(UserSetNeedDealCorrectionDocument, options);
      }
export type UserSetNeedDealCorrectionMutationHookResult = ReturnType<typeof useUserSetNeedDealCorrectionMutation>;
export type UserSetNeedDealCorrectionMutationResult = Apollo.MutationResult<UserSetNeedDealCorrectionMutation>;
export type UserSetNeedDealCorrectionMutationOptions = Apollo.BaseMutationOptions<UserSetNeedDealCorrectionMutation, UserSetNeedDealCorrectionMutationVariables>;
export const UserAcceptDealDocument = gql`
    mutation UserAcceptDeal($id: UUID!) {
  userAcceptDeal(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserAcceptDealMutationFn = Apollo.MutationFunction<UserAcceptDealMutation, UserAcceptDealMutationVariables>;

/**
 * __useUserAcceptDealMutation__
 *
 * To run a mutation, you first call `useUserAcceptDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAcceptDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAcceptDealMutation, { data, loading, error }] = useUserAcceptDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAcceptDealMutation(baseOptions?: Apollo.MutationHookOptions<UserAcceptDealMutation, UserAcceptDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserAcceptDealMutation, UserAcceptDealMutationVariables>(UserAcceptDealDocument, options);
      }
export type UserAcceptDealMutationHookResult = ReturnType<typeof useUserAcceptDealMutation>;
export type UserAcceptDealMutationResult = Apollo.MutationResult<UserAcceptDealMutation>;
export type UserAcceptDealMutationOptions = Apollo.BaseMutationOptions<UserAcceptDealMutation, UserAcceptDealMutationVariables>;
export const ManagerOfferRateDocument = gql`
    mutation ManagerOfferRate($id: UUID!, $comment: String!) {
  managerOfferRate(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerOfferRateMutationFn = Apollo.MutationFunction<ManagerOfferRateMutation, ManagerOfferRateMutationVariables>;

/**
 * __useManagerOfferRateMutation__
 *
 * To run a mutation, you first call `useManagerOfferRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerOfferRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerOfferRateMutation, { data, loading, error }] = useManagerOfferRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerOfferRateMutation(baseOptions?: Apollo.MutationHookOptions<ManagerOfferRateMutation, ManagerOfferRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerOfferRateMutation, ManagerOfferRateMutationVariables>(ManagerOfferRateDocument, options);
      }
export type ManagerOfferRateMutationHookResult = ReturnType<typeof useManagerOfferRateMutation>;
export type ManagerOfferRateMutationResult = Apollo.MutationResult<ManagerOfferRateMutation>;
export type ManagerOfferRateMutationOptions = Apollo.BaseMutationOptions<ManagerOfferRateMutation, ManagerOfferRateMutationVariables>;
export const UserAcceptRateDocument = gql`
    mutation UserAcceptRate($id: UUID!) {
  userAcceptRate(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserAcceptRateMutationFn = Apollo.MutationFunction<UserAcceptRateMutation, UserAcceptRateMutationVariables>;

/**
 * __useUserAcceptRateMutation__
 *
 * To run a mutation, you first call `useUserAcceptRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAcceptRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAcceptRateMutation, { data, loading, error }] = useUserAcceptRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAcceptRateMutation(baseOptions?: Apollo.MutationHookOptions<UserAcceptRateMutation, UserAcceptRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserAcceptRateMutation, UserAcceptRateMutationVariables>(UserAcceptRateDocument, options);
      }
export type UserAcceptRateMutationHookResult = ReturnType<typeof useUserAcceptRateMutation>;
export type UserAcceptRateMutationResult = Apollo.MutationResult<UserAcceptRateMutation>;
export type UserAcceptRateMutationOptions = Apollo.BaseMutationOptions<UserAcceptRateMutation, UserAcceptRateMutationVariables>;
export const UserDeclineRateDocument = gql`
    mutation UserDeclineRate($id: UUID!, $comment: String!) {
  userDeclineRate(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserDeclineRateMutationFn = Apollo.MutationFunction<UserDeclineRateMutation, UserDeclineRateMutationVariables>;

/**
 * __useUserDeclineRateMutation__
 *
 * To run a mutation, you first call `useUserDeclineRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeclineRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeclineRateMutation, { data, loading, error }] = useUserDeclineRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUserDeclineRateMutation(baseOptions?: Apollo.MutationHookOptions<UserDeclineRateMutation, UserDeclineRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeclineRateMutation, UserDeclineRateMutationVariables>(UserDeclineRateDocument, options);
      }
export type UserDeclineRateMutationHookResult = ReturnType<typeof useUserDeclineRateMutation>;
export type UserDeclineRateMutationResult = Apollo.MutationResult<UserDeclineRateMutation>;
export type UserDeclineRateMutationOptions = Apollo.BaseMutationOptions<UserDeclineRateMutation, UserDeclineRateMutationVariables>;
export const ManagerInvoiceUploadedDocument = gql`
    mutation ManagerInvoiceUploaded($id: UUID!, $comment: String!, $amount: Decimal!) {
  managerInvoiceUploaded(id: $id, comment: $comment, amount: $amount) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerInvoiceUploadedMutationFn = Apollo.MutationFunction<ManagerInvoiceUploadedMutation, ManagerInvoiceUploadedMutationVariables>;

/**
 * __useManagerInvoiceUploadedMutation__
 *
 * To run a mutation, you first call `useManagerInvoiceUploadedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerInvoiceUploadedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerInvoiceUploadedMutation, { data, loading, error }] = useManagerInvoiceUploadedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useManagerInvoiceUploadedMutation(baseOptions?: Apollo.MutationHookOptions<ManagerInvoiceUploadedMutation, ManagerInvoiceUploadedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerInvoiceUploadedMutation, ManagerInvoiceUploadedMutationVariables>(ManagerInvoiceUploadedDocument, options);
      }
export type ManagerInvoiceUploadedMutationHookResult = ReturnType<typeof useManagerInvoiceUploadedMutation>;
export type ManagerInvoiceUploadedMutationResult = Apollo.MutationResult<ManagerInvoiceUploadedMutation>;
export type ManagerInvoiceUploadedMutationOptions = Apollo.BaseMutationOptions<ManagerInvoiceUploadedMutation, ManagerInvoiceUploadedMutationVariables>;
export const UserInvoicePaidDocument = gql`
    mutation UserInvoicePaid($id: UUID!) {
  userInvoicePaid(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserInvoicePaidMutationFn = Apollo.MutationFunction<UserInvoicePaidMutation, UserInvoicePaidMutationVariables>;

/**
 * __useUserInvoicePaidMutation__
 *
 * To run a mutation, you first call `useUserInvoicePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInvoicePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInvoicePaidMutation, { data, loading, error }] = useUserInvoicePaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserInvoicePaidMutation(baseOptions?: Apollo.MutationHookOptions<UserInvoicePaidMutation, UserInvoicePaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserInvoicePaidMutation, UserInvoicePaidMutationVariables>(UserInvoicePaidDocument, options);
      }
export type UserInvoicePaidMutationHookResult = ReturnType<typeof useUserInvoicePaidMutation>;
export type UserInvoicePaidMutationResult = Apollo.MutationResult<UserInvoicePaidMutation>;
export type UserInvoicePaidMutationOptions = Apollo.BaseMutationOptions<UserInvoicePaidMutation, UserInvoicePaidMutationVariables>;
export const ManagerInvoicePaymentConfirmedDocument = gql`
    mutation ManagerInvoicePaymentConfirmed($id: UUID!, $comment: String!, $receiptAmount: Decimal!, $paymentDate: DateTime!) {
  managerInvoicePaymentConfirmed(
    id: $id
    comment: $comment
    receiptAmount: $receiptAmount
    paymentDate: $paymentDate
  ) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerInvoicePaymentConfirmedMutationFn = Apollo.MutationFunction<ManagerInvoicePaymentConfirmedMutation, ManagerInvoicePaymentConfirmedMutationVariables>;

/**
 * __useManagerInvoicePaymentConfirmedMutation__
 *
 * To run a mutation, you first call `useManagerInvoicePaymentConfirmedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerInvoicePaymentConfirmedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerInvoicePaymentConfirmedMutation, { data, loading, error }] = useManagerInvoicePaymentConfirmedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *      receiptAmount: // value for 'receiptAmount'
 *      paymentDate: // value for 'paymentDate'
 *   },
 * });
 */
export function useManagerInvoicePaymentConfirmedMutation(baseOptions?: Apollo.MutationHookOptions<ManagerInvoicePaymentConfirmedMutation, ManagerInvoicePaymentConfirmedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerInvoicePaymentConfirmedMutation, ManagerInvoicePaymentConfirmedMutationVariables>(ManagerInvoicePaymentConfirmedDocument, options);
      }
export type ManagerInvoicePaymentConfirmedMutationHookResult = ReturnType<typeof useManagerInvoicePaymentConfirmedMutation>;
export type ManagerInvoicePaymentConfirmedMutationResult = Apollo.MutationResult<ManagerInvoicePaymentConfirmedMutation>;
export type ManagerInvoicePaymentConfirmedMutationOptions = Apollo.BaseMutationOptions<ManagerInvoicePaymentConfirmedMutation, ManagerInvoicePaymentConfirmedMutationVariables>;
export const ManagerPaymentToSupplierConfirmedDocument = gql`
    mutation ManagerPaymentToSupplierConfirmed($id: UUID!, $comment: String!) {
  managerPaymentToSupplierConfirmed(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerPaymentToSupplierConfirmedMutationFn = Apollo.MutationFunction<ManagerPaymentToSupplierConfirmedMutation, ManagerPaymentToSupplierConfirmedMutationVariables>;

/**
 * __useManagerPaymentToSupplierConfirmedMutation__
 *
 * To run a mutation, you first call `useManagerPaymentToSupplierConfirmedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerPaymentToSupplierConfirmedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerPaymentToSupplierConfirmedMutation, { data, loading, error }] = useManagerPaymentToSupplierConfirmedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useManagerPaymentToSupplierConfirmedMutation(baseOptions?: Apollo.MutationHookOptions<ManagerPaymentToSupplierConfirmedMutation, ManagerPaymentToSupplierConfirmedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerPaymentToSupplierConfirmedMutation, ManagerPaymentToSupplierConfirmedMutationVariables>(ManagerPaymentToSupplierConfirmedDocument, options);
      }
export type ManagerPaymentToSupplierConfirmedMutationHookResult = ReturnType<typeof useManagerPaymentToSupplierConfirmedMutation>;
export type ManagerPaymentToSupplierConfirmedMutationResult = Apollo.MutationResult<ManagerPaymentToSupplierConfirmedMutation>;
export type ManagerPaymentToSupplierConfirmedMutationOptions = Apollo.BaseMutationOptions<ManagerPaymentToSupplierConfirmedMutation, ManagerPaymentToSupplierConfirmedMutationVariables>;
export const UserPaymentToSupplierFailedDocument = gql`
    mutation UserPaymentToSupplierFailed($id: UUID!, $comment: String!) {
  userPaymentToSupplierFailed(id: $id, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserPaymentToSupplierFailedMutationFn = Apollo.MutationFunction<UserPaymentToSupplierFailedMutation, UserPaymentToSupplierFailedMutationVariables>;

/**
 * __useUserPaymentToSupplierFailedMutation__
 *
 * To run a mutation, you first call `useUserPaymentToSupplierFailedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentToSupplierFailedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPaymentToSupplierFailedMutation, { data, loading, error }] = useUserPaymentToSupplierFailedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUserPaymentToSupplierFailedMutation(baseOptions?: Apollo.MutationHookOptions<UserPaymentToSupplierFailedMutation, UserPaymentToSupplierFailedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPaymentToSupplierFailedMutation, UserPaymentToSupplierFailedMutationVariables>(UserPaymentToSupplierFailedDocument, options);
      }
export type UserPaymentToSupplierFailedMutationHookResult = ReturnType<typeof useUserPaymentToSupplierFailedMutation>;
export type UserPaymentToSupplierFailedMutationResult = Apollo.MutationResult<UserPaymentToSupplierFailedMutation>;
export type UserPaymentToSupplierFailedMutationOptions = Apollo.BaseMutationOptions<UserPaymentToSupplierFailedMutation, UserPaymentToSupplierFailedMutationVariables>;
export const UserActSentDocument = gql`
    mutation UserActSent($id: UUID!) {
  userActSent(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UserActSentMutationFn = Apollo.MutationFunction<UserActSentMutation, UserActSentMutationVariables>;

/**
 * __useUserActSentMutation__
 *
 * To run a mutation, you first call `useUserActSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserActSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userActSentMutation, { data, loading, error }] = useUserActSentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserActSentMutation(baseOptions?: Apollo.MutationHookOptions<UserActSentMutation, UserActSentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserActSentMutation, UserActSentMutationVariables>(UserActSentDocument, options);
      }
export type UserActSentMutationHookResult = ReturnType<typeof useUserActSentMutation>;
export type UserActSentMutationResult = Apollo.MutationResult<UserActSentMutation>;
export type UserActSentMutationOptions = Apollo.BaseMutationOptions<UserActSentMutation, UserActSentMutationVariables>;
export const ManagerFinishOrderDocument = gql`
    mutation ManagerFinishOrder($id: UUID!) {
  managerFinishOrder(id: $id) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type ManagerFinishOrderMutationFn = Apollo.MutationFunction<ManagerFinishOrderMutation, ManagerFinishOrderMutationVariables>;

/**
 * __useManagerFinishOrderMutation__
 *
 * To run a mutation, you first call `useManagerFinishOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManagerFinishOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [managerFinishOrderMutation, { data, loading, error }] = useManagerFinishOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManagerFinishOrderMutation(baseOptions?: Apollo.MutationHookOptions<ManagerFinishOrderMutation, ManagerFinishOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManagerFinishOrderMutation, ManagerFinishOrderMutationVariables>(ManagerFinishOrderDocument, options);
      }
export type ManagerFinishOrderMutationHookResult = ReturnType<typeof useManagerFinishOrderMutation>;
export type ManagerFinishOrderMutationResult = Apollo.MutationResult<ManagerFinishOrderMutation>;
export type ManagerFinishOrderMutationOptions = Apollo.BaseMutationOptions<ManagerFinishOrderMutation, ManagerFinishOrderMutationVariables>;
export const SetOrderStatusDocument = gql`
    mutation SetOrderStatus($id: UUID!, $status: EOrderStatus!, $comment: String!) {
  adminSetOrderStatus(id: $id, status: $status, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type SetOrderStatusMutationFn = Apollo.MutationFunction<SetOrderStatusMutation, SetOrderStatusMutationVariables>;

/**
 * __useSetOrderStatusMutation__
 *
 * To run a mutation, you first call `useSetOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrderStatusMutation, { data, loading, error }] = useSetOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSetOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetOrderStatusMutation, SetOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrderStatusMutation, SetOrderStatusMutationVariables>(SetOrderStatusDocument, options);
      }
export type SetOrderStatusMutationHookResult = ReturnType<typeof useSetOrderStatusMutation>;
export type SetOrderStatusMutationResult = Apollo.MutationResult<SetOrderStatusMutation>;
export type SetOrderStatusMutationOptions = Apollo.BaseMutationOptions<SetOrderStatusMutation, SetOrderStatusMutationVariables>;
export const RemoveDocumentDocument = gql`
    mutation RemoveDocument($orderId: UUID!, $documentId: UUID!) {
  managerRemoveDocument(id: $orderId, documentId: $documentId) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type RemoveDocumentMutationFn = Apollo.MutationFunction<RemoveDocumentMutation, RemoveDocumentMutationVariables>;

/**
 * __useRemoveDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentMutation, { data, loading, error }] = useRemoveDocumentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useRemoveDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDocumentMutation, RemoveDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDocumentMutation, RemoveDocumentMutationVariables>(RemoveDocumentDocument, options);
      }
export type RemoveDocumentMutationHookResult = ReturnType<typeof useRemoveDocumentMutation>;
export type RemoveDocumentMutationResult = Apollo.MutationResult<RemoveDocumentMutation>;
export type RemoveDocumentMutationOptions = Apollo.BaseMutationOptions<RemoveDocumentMutation, RemoveDocumentMutationVariables>;
export const SetStatusDocument = gql`
    mutation SetStatus($id: UUID!, $status: EOrderStatus!, $comment: String!) {
  adminSetOrderStatus(id: $id, status: $status, comment: $comment) {
    ...OrderFields
  }
}
    ${OrderFieldsFragmentDoc}`;
export type SetStatusMutationFn = Apollo.MutationFunction<SetStatusMutation, SetStatusMutationVariables>;

/**
 * __useSetStatusMutation__
 *
 * To run a mutation, you first call `useSetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStatusMutation, { data, loading, error }] = useSetStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSetStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetStatusMutation, SetStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStatusMutation, SetStatusMutationVariables>(SetStatusDocument, options);
      }
export type SetStatusMutationHookResult = ReturnType<typeof useSetStatusMutation>;
export type SetStatusMutationResult = Apollo.MutationResult<SetStatusMutation>;
export type SetStatusMutationOptions = Apollo.BaseMutationOptions<SetStatusMutation, SetStatusMutationVariables>;
export const OrderMessagesDocument = gql`
    query OrderMessages($id: UUID!) {
  orderMessages(id: $id) {
    ...OrderMessage
  }
}
    ${OrderMessageFragmentDoc}`;

/**
 * __useOrderMessagesQuery__
 *
 * To run a query within a React component, call `useOrderMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderMessagesQuery(baseOptions: Apollo.QueryHookOptions<OrderMessagesQuery, OrderMessagesQueryVariables> & ({ variables: OrderMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderMessagesQuery, OrderMessagesQueryVariables>(OrderMessagesDocument, options);
      }
export function useOrderMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderMessagesQuery, OrderMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderMessagesQuery, OrderMessagesQueryVariables>(OrderMessagesDocument, options);
        }
export function useOrderMessagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrderMessagesQuery, OrderMessagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderMessagesQuery, OrderMessagesQueryVariables>(OrderMessagesDocument, options);
        }
export type OrderMessagesQueryHookResult = ReturnType<typeof useOrderMessagesQuery>;
export type OrderMessagesLazyQueryHookResult = ReturnType<typeof useOrderMessagesLazyQuery>;
export type OrderMessagesSuspenseQueryHookResult = ReturnType<typeof useOrderMessagesSuspenseQuery>;
export type OrderMessagesQueryResult = Apollo.QueryResult<OrderMessagesQuery, OrderMessagesQueryVariables>;
export const AddOrderTextMessageDocument = gql`
    mutation AddOrderTextMessage($orderId: UUID!, $message: String!) {
  addOrderTextMessage(orderId: $orderId, message: $message) {
    status
  }
}
    `;
export type AddOrderTextMessageMutationFn = Apollo.MutationFunction<AddOrderTextMessageMutation, AddOrderTextMessageMutationVariables>;

/**
 * __useAddOrderTextMessageMutation__
 *
 * To run a mutation, you first call `useAddOrderTextMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderTextMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderTextMessageMutation, { data, loading, error }] = useAddOrderTextMessageMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddOrderTextMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderTextMessageMutation, AddOrderTextMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderTextMessageMutation, AddOrderTextMessageMutationVariables>(AddOrderTextMessageDocument, options);
      }
export type AddOrderTextMessageMutationHookResult = ReturnType<typeof useAddOrderTextMessageMutation>;
export type AddOrderTextMessageMutationResult = Apollo.MutationResult<AddOrderTextMessageMutation>;
export type AddOrderTextMessageMutationOptions = Apollo.BaseMutationOptions<AddOrderTextMessageMutation, AddOrderTextMessageMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UsersDocument = gql`
    query Users($cursor: String) {
  users(first: 30, after: $cursor, order: {createdAt: DESC}) {
    edges {
      node {
        ...ItemUser
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ItemUserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const ManagersDocument = gql`
    query Managers {
  users(
    first: 100
    order: {createdAt: DESC}
    where: {role: {in: [MANAGER, ADMIN]}}
  ) {
    edges {
      node {
        ...ItemUser
      }
    }
  }
}
    ${ItemUserFragmentDoc}`;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagersQuery(baseOptions?: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
      }
export function useManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
        }
export function useManagersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
        }
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<typeof useManagersLazyQuery>;
export type ManagersSuspenseQueryHookResult = ReturnType<typeof useManagersSuspenseQuery>;
export type ManagersQueryResult = Apollo.QueryResult<ManagersQuery, ManagersQueryVariables>;
export const UserDocument = gql`
    query User($id: UUID!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!, $phone: String!, $role: EUserRole!, $telegramUsername: String, $password: String!) {
  saveUser(
    name: $name
    email: $email
    phone: $phone
    role: $role
    telegramUsername: $telegramUsername
    password: $password
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *      telegramUsername: // value for 'telegramUsername'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const SaveUserDocument = gql`
    mutation SaveUser($id: UUID!, $name: String!, $email: String!, $phone: String!, $role: EUserRole!, $telegramUsername: String, $password: String) {
  saveUser(
    id: $id
    name: $name
    email: $email
    phone: $phone
    role: $role
    telegramUsername: $telegramUsername
    password: $password
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type SaveUserMutationFn = Apollo.MutationFunction<SaveUserMutation, SaveUserMutationVariables>;

/**
 * __useSaveUserMutation__
 *
 * To run a mutation, you first call `useSaveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserMutation, { data, loading, error }] = useSaveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *      telegramUsername: // value for 'telegramUsername'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSaveUserMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserMutation, SaveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserMutation, SaveUserMutationVariables>(SaveUserDocument, options);
      }
export type SaveUserMutationHookResult = ReturnType<typeof useSaveUserMutation>;
export type SaveUserMutationResult = Apollo.MutationResult<SaveUserMutation>;
export type SaveUserMutationOptions = Apollo.BaseMutationOptions<SaveUserMutation, SaveUserMutationVariables>;