import { Outlet } from 'react-router-dom'
import { Center, Flex, Grid, Spinner } from '@chakra-ui/react'
import { Menu } from './Menu'
import { useCurrentUser } from 'state/useCurrentUser'
import { useEffect } from 'react'

export const MainLayout = () => {
  const { getCurrentUser, isAuthenticated, isLoading, isCalled } = useCurrentUser()

  useEffect(() => {
    getCurrentUser()
  }, [])

  if (isAuthenticated) {
    return (
      <Grid flex={1} templateColumns="260px 1fr">
        <Menu/>
        <Flex padding="8px">
          <Flex
            flex={1}
            borderRadius="8px"
            borderWidth={1}
            backgroundColor="white"
            overflowY="scroll"
            overflowX="hidden"
            height="calc(100vh - 16px)"
          >
            <Flex flexDirection="column" flex={1}>
              <Outlet/>
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    )
  }

  if (!isCalled || isLoading) {
    return (
      <Center flex={1}>
        <Spinner boxSize="18px"/>
      </Center>
    )
  }

  return (
    <Center flex={1}>
      Нет доступа
    </Center>
  )
}
