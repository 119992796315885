import { Navigate, Route, Routes } from 'react-router-dom'
import { MainLayout } from 'shared/layouts/MainLayout'
import { Companies } from './routes/Companies'
import { Orders } from './routes/Orders'
import { Control } from './routes/Control'
import { useCurrentUser } from 'state/useCurrentUser'

export const Main = () => {
  const { isControl } = useCurrentUser()

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate to={isControl ? '/control/orders': '/orders'} />} />
        {!isControl && (
          <>
            <Route path="orders/*" element={<Orders />} />
            <Route path="companies/*" element={<Companies />} />
          </>
        )}
        <Route path="control/*" element={<Control />} />
      </Route>
    </Routes>
  )
}
