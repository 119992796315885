import { Center, Spinner } from '@chakra-ui/react'
import { useCurrentUser } from '../../state/useCurrentUser'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Logout = () => {
  const { logout } = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    logout().then(() => navigate('/login'))
  }, [])

  return (
    <Center flex={1}>
      <Spinner boxSize="18px"/>
    </Center>
  )
}
