import React from 'react'
import { useUserContractSignedMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { UploadButton } from '../../Upload/UploadButton'
import { StepWrapper } from '../../../elements/StepWrapper'

export const EdiContactSigningStep = () => {
  const { orderId, orderData } = useOrderData()
  const [userContractSignedMutation] = useUserContractSignedMutation()
  const { refetchOrder } = useRefetchOrder()

  const handleFinish = async () => {
    if (!orderId) return
    await userContractSignedMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  if (orderData?.edi) {
    return (
      <StepWrapper description="Подпишите договор в системе ЭДО. Загрузите в форму подписанный документ">
        <UploadButton
          documentType="SignedContract"
          selectLabelButton="Выбрать контракт"
          uploadLabelButton="Отправить"
          onFinish={handleFinish}
        />
      </StepWrapper>
    )
  }

  return (
    <StepWrapper description="Подпишите договор, приложите сканы в форму. Оргиналы договоров направьте нам почтой или курьером">
      <UploadButton
        documentType="SignedContract"
        selectLabelButton="Выбрать"
        uploadLabelButton="Отправить"
        onFinish={handleFinish}
      />
    </StepWrapper>
  )
}
