import { EntityListRow } from 'components/Entity/EntityListRow'
import { Icon } from '@chakra-ui/icon'
import { LuFile } from 'react-icons/lu'
import { Box, CircularProgress, HStack } from '@chakra-ui/react'
import React from 'react'
import { ItemOrderFragment } from 'api/graphql'
import moment from 'moment'
import { formatNumber } from '../../utils/formatNumber'
import { truncate } from 'lodash'
import { Badge } from 'uikit/Badge'
import { orderStatusColor, orderStatusLabel, orderStatusProgress } from 'constants/orderStatus'
import { AnimDot } from 'uikit/AnimDot'
import { useOrderNotification } from '../hooks/useOrderNotification'

interface RowOrderProps {
  data: ItemOrderFragment
}

export const OrderControlRow: React.FC<RowOrderProps> = ({ data }) => {
  const { isShowDot } = useOrderNotification(data)
  return (
    <EntityListRow
      to={`${data.id}`}
      templateColumns="20px minmax(180px, auto) minmax(200px, 1fr) minmax(120px, 330px) minmax(120px, auto) 20px"
    >
      <Box position="relative">
        {isShowDot && (
          <AnimDot position="absolute" bottom="4px" right="4px"/>
        )}
        <Icon as={LuFile} zIndex={1} width="16px" />
      </Box>
      <HStack fontSize="0.9rem">
        <Box color="gray.400">№:</Box>
        <Box>{formatNumber(data.orderNumber)}</Box>
      </HStack>
      <Box fontSize="0.9rem" overflow="hidden" css={{ textWrap: 'nowrap' }}>
        {truncate(data.company.name ?? '', { length: 60 })}
      </Box>
      <HStack spacing="14px" alignItems="center" overflow="hidden">
        <Badge colorScheme={orderStatusColor[data.status]}>
          {orderStatusLabel[data.status]}
        </Badge>
      </HStack>
      <Box fontSize="0.9rem">
        {moment(data.createdAt).format('DD.MM.YYYY HH:mm')}
      </Box>
      <CircularProgress
        capIsRound
        trackColor="gray.100"
        size="20px"
        thickness="14px"
        value={orderStatusProgress[data.status ?? 10]}
        min={0}
        max={200}
        color="purple.500"
      />
    </EntityListRow>
  )
}
