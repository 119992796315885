import React from 'react'
import { UploadButton } from '../../Upload/UploadButton'
import { StepWrapper } from '../../../elements/StepWrapper'

export const InvoiceStep = () => {
  return (
    <StepWrapper description="Загрузите документ, который подтверждает факт совершения коммерческой сделки и выставляет счет за предоставленные товары или услуги.">
      <UploadButton documentType="Invoice" selectLabelButton="Выбрать инвойс" />
    </StepWrapper>
  )
}
