import { Button, HStack, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { StepWrapper } from '../../../elements/StepWrapper'
import { FileItem } from '../../../elements/FileItem'
import { EDocumentType, useManagerInvoicePaymentConfirmedMutation } from 'api/graphql'
import { TextAreaForm } from 'uikit/TextArea'
import moment from 'moment'
import { Field, Formik, FormikValues } from 'formik'
import { InputMaskForm } from 'uikit/InputMask'
import { InputGroupForm } from 'uikit/InputGroup'
import * as Yup from 'yup'

export const PaymentVerificationStep = () => {
  const { orderId, documentsData } = useOrderData()
  const { refetchOrder } = useRefetchOrder()

  const [managerInvoicePaymentConfirmedMutation, { loading: isLoading }] = useManagerInvoicePaymentConfirmedMutation()

  const documents = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.PaidInvoice
      ),
    [documentsData]
  )

  const handleForm = async (values: FormikValues) => {
    if (!orderId) return
    await managerInvoicePaymentConfirmedMutation({
      variables: {
        id: orderId,
        comment: values?.comment,
        receiptAmount: Number(values?.receiptAmount),
        paymentDate: moment(
          `${values.date} ${values.time}`,
          'DD.MM.YYYY HH:mm'
        ).utcOffset('+03:00'),
      },
    })
    await refetchOrder()
  }

  return (
    <Stack spacing="14px">
      <StepWrapper description="Проверьте оплаченные счета">
        <Stack spacing="10px">
          {documents?.map((document) => (
            <FileItem
              key={document.id}
              id={document.id}
              name={document?.fileName}
              orderId={orderId}
              cursor="pointer"
              backgroundColor="white"
              _hover={{
                backgroundColor: 'gray.200',
              }}
              _active={{
                backgroundColor: 'gray.300',
              }}
            />
          ))}
        </Stack>
      </StepWrapper>
      <StepWrapper>
        <Formik
          validationSchema={formSchema}
          initialValues={{
            comment: '',
            receiptAmount: '',
            date: moment().format('DD.MM.YYYY'),
            time: moment().format('HH:mm'),
          }}
          onSubmit={handleForm}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing="24px">
                <Stack spacing="18px">
                  <HStack spacing="14px" alignItems="flex-start">
                    <Field
                      name="date"
                      label="Дата поступления оплаты"
                      mask={[
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      component={InputMaskForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="time"
                      label="Время"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                      component={InputMaskForm}
                      isDisabled={isLoading}
                    />
                  </HStack>
                  <Field
                    name="receiptAmount"
                    label="Сумма поступивших средств"
                    component={InputGroupForm}
                    isDisabled={isLoading}
                  />
                  <Field
                    name="comment"
                    label="Комментарий"
                    placeholder="По желанию"
                    component={TextAreaForm}
                    isDisabled={isLoading}
                  />
                </Stack>
                <HStack spacing="16px">
                  <Button
                    isDisabled={!props.dirty}
                    isLoading={isLoading}
                    type="submit"
                    variant="primary"
                  >
                    Отправить
                  </Button>
                </HStack>
              </Stack>
            </form>
          )}
        </Formik>
      </StepWrapper>
    </Stack>
  )
}

const formSchema = Yup.object().shape({
  date: Yup.string()
    .required('Заполните дату')
    .test('isValidDate', 'Некорректная дата', (value) =>
      moment(value, 'DD.MM.YYYY', true).isValid()
    ),
  time: Yup.string()
    .required('Заполните время')
    .test('isValidTime', 'Некорректное время', (value) =>
      moment(value, 'HH:mm', true).isValid()
    ),
  receiptAmount: Yup.string()
    .matches(/^\d+$/, 'Сумма должна быть числом без точек, запятых и пробелов')
    .required('Сумма обязательна')
    .test('is-positive', 'Сумма должна быть больше нуля', value => parseInt(value, 10) > 0),
})
