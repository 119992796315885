import { EDocumentType } from '../api/graphql'

export const documentLabel = {
  [EDocumentType.Act]: 'Акт на подпись',
  [EDocumentType.DealDocuments]: 'Документ по заявке',
  [EDocumentType.Contract]: 'Контракт',
  [EDocumentType.Draft]: 'Драфт для заполнения',
  [EDocumentType.Invoice]: 'Инвойс',
  [EDocumentType.PaymentInvoice]: 'Счет на оплату',
  [EDocumentType.PaidInvoice]: 'Оплаченный счет',
  [EDocumentType.SignedAct]: 'Подписанный акты',
  [EDocumentType.Swift]: 'SWIFT документ',
  [EDocumentType.ContractAppendix]: 'Приложение к договору',
  [EDocumentType.SignedContract]: 'Подписанный контракт',
  [EDocumentType.PayerDetails]: 'Реквизиты плательщика',
  [EDocumentType.Other]: 'Другое',
}
