import React, { useMemo } from 'react'
import { EDocumentType, useManagerInvoiceUploadedMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { TextAreaForm } from 'uikit/TextArea'
import { UploadButton } from '../../Upload/UploadButton'
import { StepWrapper } from '../../../elements/StepWrapper'
import { InputGroupForm } from 'uikit/InputGroup'
import { isEmpty } from 'lodash'
import { Box, Button, Divider, HStack, Stack } from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import { FileItem } from '../../../elements/FileItem'
import * as Yup from 'yup'
import { FormLabel } from 'uikit/FormLabel'

export const InvoicePaymentStep = () => {
  const { orderId, documentsData } = useOrderData()
  const [managerInvoiceUploadedMutation, { loading: isInvoiceUploadedLoading }] =
    useManagerInvoiceUploadedMutation()
  const { refetchOrder } = useRefetchOrder()

  const paymentInvoiceDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.PaymentInvoice
      ),
    [documentsData]
  )

  const contractAppendixDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.ContractAppendix
      ),
    [documentsData]
  )

  const handleForm = async (values: FormikValues) => {
    if (!orderId) return
    await managerInvoiceUploadedMutation({
      variables: {
        id: orderId,
        comment: values.comment,
        amount: Number(values.amount),
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper description="Сформируйте счет для оплаты, и напишите иструкцию о дальнейших действиях">
      {!isEmpty(paymentInvoiceDocuments) && (
        <Box>
          <FormLabel>
            Загруженные счета
          </FormLabel>
          <Stack spacing="10px">
            {paymentInvoiceDocuments?.map((document) => (
              <FileItem
                key={document.id}
                id={document.id}
                name={document?.fileName}
                orderId={orderId}
                cursor="pointer"
                backgroundColor="white"
                _hover={{
                  backgroundColor: 'gray.200',
                }}
                _active={{
                  backgroundColor: 'gray.300',
                }}
              />
            ))}
          </Stack>
        </Box>
      )}
      {isEmpty(paymentInvoiceDocuments) && (
        <UploadButton
          documentType="PaymentInvoice"
          selectLabelButton="Выбрать счет"
          uploadLabelButton="Загрузить"
        />
      )}
      {!isEmpty(paymentInvoiceDocuments) && isEmpty(contractAppendixDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Box>
            <FormLabel>
              Приложение к договору
            </FormLabel>
            <UploadButton
              documentType="ContractAppendix"
              selectLabelButton="Выбрать приложение к договру"
              uploadLabelButton="Загрузить"
            />
          </Box>
        </>
      )}
      {!isEmpty(contractAppendixDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Box>
            <FormLabel>
              Приложение к договору
            </FormLabel>
            <Stack spacing="10px">
              {contractAppendixDocuments?.map((document) => (
                <FileItem
                  key={document.id}
                  id={document.id}
                  name={document?.fileName}
                  orderId={orderId}
                  cursor="pointer"
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: 'gray.200',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                />
              ))}
            </Stack>
          </Box>
        </>
      )}
      {!isEmpty(paymentInvoiceDocuments) && !isEmpty(contractAppendixDocuments) && (
        <>
          <Divider borderColor="gray.300" />
          <Formik
            validationSchema={formSchema}
            initialValues={{
              comment: '',
              amount: '',
            }}
            onSubmit={handleForm}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Stack spacing="24px">
                  <Stack spacing="18px">
                    <Field
                      name="amount"
                      label="Сумма счета в рублях"
                      component={InputGroupForm}
                      isDisabled={isInvoiceUploadedLoading}
                    />
                    <Field
                      name="comment"
                      label="Инструкция"
                      component={TextAreaForm}
                      isDisabled={isInvoiceUploadedLoading}
                    />
                  </Stack>
                  <HStack spacing="16px">
                    <Button
                      isDisabled={!props.dirty}
                      isLoading={isInvoiceUploadedLoading}
                      type="submit"
                      variant="primary"
                    >
                      Отправить
                    </Button>
                  </HStack>
                </Stack>
              </form>
            )}
          </Formik>
        </>
      )}
    </StepWrapper>
  )
}

const formSchema = Yup.object().shape({
  comment: Yup.string().required(''),
  amount: Yup.string()
    .matches(/^\d+$/, 'Сумма должна быть числом без точек, запятых и пробелов')
    .required('Сумма обязательна')
    .test('is-positive', 'Сумма должна быть больше нуля', value => parseInt(value, 10) > 0)
})
