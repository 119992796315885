import { Box, BoxProps, keyframes } from '@chakra-ui/react'
import React from 'react'

interface AnimDotProps extends BoxProps {
  dotColor?: string
}

export const AnimDot: React.FC<AnimDotProps> = ({ dotColor = 'red.500', ...props }) => {
  return (
    <Box
      boxSize="7px"
      backgroundColor={dotColor}
      borderRadius="full"
      {...props}
    >
      <Box
        borderRadius="full"
        backgroundColor={dotColor}
        animation={`${pulse} 2s infinite`}
      />
    </Box>
  )
}

const pulse = keyframes`
    0% {
        opacity: .5;
        height: 7px;
        width: 7px;
        transition: opacity .5s linear;
    }
    50% {
        opacity: 0;
        transform: scale(3);
        transition: opacity .5s linear;
    }
    100% {
        opacity: 0;
        transition: opacity .5s ease;
    }
`
