import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocOfferModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocOfferModal: React.FC<DocOfferModalProps> = ({ isOpen, onClose }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <h1 style={{ color: '#333', textAlign: 'center' }}>ДОГОВОР ОФЕРТА</h1>
      <p style={{ margin: '10px 0', textAlign: 'center' }}>на предоставление услуг на Сайте</p>

      <p style={{ margin: '10px 0' }}>
        Настоящий Договор-оферта (далее – «Договор» или «Оферта») является предложением Общества с ограниченной
        ответственностью <span>___</span>, именуемого в дальнейшем «Исполнитель», заключить с любым лицом, именуемым в
        дальнейшем «Заказчик», Договор возмездного оказания услуг на условиях, изложенных в настоящей Оферте.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>1. ПРЕДМЕТ ДОГОВОРА</h2>
      <p style={{ margin: '10px 0' }}>
        1.1. Исполнитель обязуется предоставить Заказчику услуги, указанные на сайте <span>___</span> (далее – «Сайт»),
        на условиях настоящего Договора, а Заказчик обязуется принять и оплатить такие услуги.
      </p>
      <p style={{ margin: '10px 0' }}>
        1.2. Настоящий Договор регулирует порядок и условия предоставления Исполнителем услуг Заказчику на Сайте, а
        также устанавливает права и обязанности сторон по его исполнению.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>2. УСЛОВИЯ ОКАЗАНИЯ
        УСЛУГ</h2>
      <p style={{ margin: '10px 0' }}>
        2.1. Исполнитель предоставляет Заказчику услуги в соответствии с условиями, изложенными в настоящем Договоре и
        на Сайте.
      </p>
      <p style={{ margin: '10px 0' }}>
        2.2. Для заказа услуг Заказчику необходимо пройти регистрацию на Сайте и оплатить выбранные услуги в
        соответствии с условиями оплаты, установленными на Сайте.
      </p>
      <p style={{ margin: '10px 0' }}>
        2.3. Исполнитель вправе в любое время вносить изменения в условия оказания услуг, публикуя такие изменения на
        Сайте. Изменения вступают в силу с момента их публикации.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>3. ПРАВА И ОБЯЗАННОСТИ
        СТОРОН</h2>
      <p style={{ margin: '10px 0' }}>
        3.1. Исполнитель обязуется:
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Предоставлять услуги в соответствии с условиями настоящего Договора.</li>
        <li style={{ margin: '10px 0' }}>Обеспечить доступность информации о предоставляемых услугах на Сайте.</li>
        <li style={{ margin: '10px 0' }}>Своевременно уведомлять Заказчика о любых изменениях в условиях оказания
          услуг.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>
        3.2. Исполнитель имеет право:
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Вносить изменения в условия оказания услуг, публикуя такие изменения на
          Сайте.
        </li>
        <li style={{ margin: '10px 0' }}>Отказаться от оказания услуг в случае нарушения Заказчиком условий настоящего
          Договора.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>
        3.3. Заказчик обязуется:
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Оплатить услуги в соответствии с условиями настоящего Договора.</li>
        <li style={{ margin: '10px 0' }}>Соблюдать условия настоящего Договора и инструкции Исполнителя, размещенные на
          Сайте.
        </li>
        <li style={{ margin: '10px 0' }}>Не предоставлять доступ к своему аккаунту на Сайте третьим лицам.</li>
      </ul>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>4. ОПЛАТА УСЛУГ</h2>
      <p style={{ margin: '10px 0' }}>
        4.1. Стоимость услуг определяется в соответствии с тарифами, размещенными на Сайте.
      </p>
      <p style={{ margin: '10px 0' }}>
        4.2. Оплата услуг осуществляется в порядке, установленном на Сайте, с использованием доступных способов оплаты.
      </p>
      <p style={{ margin: '10px 0' }}>
        4.3. Исполнитель вправе в одностороннем порядке изменять стоимость услуг, публикуя изменения на Сайте.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>5. ОТВЕТСТВЕННОСТЬ
        СТОРОН</h2>
      <p style={{ margin: '10px 0' }}>
        5.1. За неисполнение или ненадлежащее исполнение своих обязательств по настоящему Договору стороны несут
        ответственность в соответствии с действующим законодательством Российской Федерации.
      </p>
      <p style={{ margin: '10px 0' }}>
        5.2. Исполнитель не несет ответственности за невозможность оказания услуг, если такая невозможность вызвана
        обстоятельствами, не зависящими от Исполнителя (форс-мажор).
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>6. РАЗРЕШЕНИЕ СПОРОВ</h2>
      <p style={{ margin: '10px 0' }}>
        6.1. Все споры и разногласия, возникающие из настоящего Договора, решаются путем переговоров.
      </p>
      <p style={{ margin: '10px 0' }}>
        6.2. В случае невозможности урегулирования споров путем переговоров, они подлежат разрешению в судебном порядке
        в соответствии с действующим законодательством Российской Федерации.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>7. СРОК ДЕЙСТВИЯ
        ДОГОВОРА</h2>
      <p style={{ margin: '10px 0' }}>
        7.1. Настоящий Договор вступает в силу с момента акцепта Оферты Заказчиком и действует до полного выполнения
        сторонами своих обязательств.
      </p>
      <p style={{ margin: '10px 0' }}>
        7.2. Исполнитель вправе в любое время изменить условия настоящего Договора, опубликовав изменения на Сайте.
        Изменения вступают в силу с момента их публикации.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>8. ЗАКЛЮЧИТЕЛЬНЫЕ
        ПОЛОЖЕНИЯ</h2>
      <p style={{ margin: '10px 0' }}>
        8.1. Настоящий Договор регулируется и толкуется в соответствии с законодательством Российской Федерации.
      </p>
      <p style={{ margin: '10px 0' }}>
        8.2. Все уведомления и сообщения между сторонами осуществляются в письменной форме по электронной почте или
        другим доступным способом, указанным на Сайте.
      </p>
      <p style={{ margin: '10px 0' }}>
        8.3. Если любое из положений настоящего Договора будет признано недействительным или неисполнимым, остальные
        положения остаются в силе и подлежат исполнению.
      </p>

      <p style={{ margin: '10px 0', textAlign: 'center' }}><strong>ДАТА СОСТАВЛЕНИЯ
        ДОГОВОРА: <span>___</span>.06.2024</strong></p>
    </DocumentModal>
  )
}
