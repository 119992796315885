import { Route, Routes } from 'react-router-dom'
import { ControlLayout } from 'shared/layouts/ControlLayout'
import { Orders } from './routes/Orders'
import { Users } from './routes/Users'

export const Control = () => {
  return (
    <Routes>
      <Route path="/" element={<ControlLayout/>}>
        <Route path="orders/*" element={<Orders/>} />
        <Route path="users/*" element={<Users/>} />
      </Route>
    </Routes>
  )
}
