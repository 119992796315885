import { extendTheme } from '@chakra-ui/react'
import { Button } from './Button'
import { Input } from './Input'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        //minWidth: '360px',
        minWidth: '1000px',
        height: '100%',
        width: '100%',
        backgroundColor: '#F4F4F4'
      },
    },
  },
  colors: {
    gray: {
      50: '#F9F9F9',
      100: '#F4F4F4',
      200: '#E9E9E9',
      300: '#DFDFDF',
      400: '#B6B6B6',
      500: '#959595',
      600: '#696969',
      700: '#464646',
      800: '#2B2B2B',
      900: '#242424',
    },
  },
  layerStyles: {
    link: {
      color: 'blue.500',
      transition: '0.3s color',
      cursor: 'pointer',
      _hover: {
        color: 'blue.600',
      }
    },
  },
  components: {
    Button,
    Input
  },
  fonts: {
    heading: 'Gramatika, sans-serif',
    body: 'Gramatika, sans-serif',
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
})
