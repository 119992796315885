import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocPersonalDataModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocPersonalDataModal: React.FC<DocPersonalDataModalProps> = ({ isOpen, onClose }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ОСНОВНЫЕ ПОЛОЖЕНИЯ</h2>
      <p style={{ margin: '10px 0' }}>
        Политика использования на сайте <span>___</span> (далее – «Сайт») компании ООО «<span>___</span>» (далее –
        «Компания») файлов cookie (далее — «Политика») определяет правила, по которым Компания собирает и обрабатывает
        информацию о пользователях сайта.
      </p>
      <p style={{ margin: '10px 0' }}>
        Настоящая Политика является частью Политики конфиденциальности Компании. Политика описывает типы файлов cookie,
        цели их использования и способы, с помощью которых можно от них отказаться.
      </p>
      <p style={{ margin: '10px 0' }}>
        Файлы cookie — это небольшие фрагменты данных с информацией о посещении Сайта, которые сервер отправляет на Ваше
        устройство. Файлы cookie запоминают информацию о ваших предпочтениях, позволяют в более удобном для Вас режиме
        просматривать посещенные сайты в течение определенного времени. Например, благодаря файлам cookie Сайт
        предлагает Вам просматривать информацию на том языке, которым Вы обычно пользуетесь.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания может использовать файлы cookie для того чтобы:
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>помогать вам оставаться авторизованным на Сайте;</li>
        <li style={{ margin: '10px 0' }}>улучшать качество вашей работы с Сайтом;</li>
        <li style={{ margin: '10px 0' }}>показывать приоритетную для Вас информацию;</li>
        <li style={{ margin: '10px 0' }}>сохранять ваши настройки предпочтений;</li>
        <li style={{ margin: '10px 0' }}>отображать рекламу, которая может вас заинтересовать;</li>
        <li style={{ margin: '10px 0' }}>анализировать статистику использования Сайта.</li>
      </ul>
      <p style={{ margin: '10px 0' }}>
        На основании данных, полученных с помощью файлов cookie, Компания разрабатывает наиболее полезный функционал для
        Сайта, которым вы пользуетесь, проводит статистические и маркетинговые исследования, исправляет ошибки на Сайте
        и тестирует новые функции для повышения производительности Сайта, персонализирует и показывает наиболее
        релевантную для Вас информацию.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания не допускает применения рекомендательных технологий, которые нарушают права и законные интересы граждан
        и организаций, а также не допускает применение рекомендательных технологий в целях предоставления информации с
        нарушением законодательства Российской Федерации. Под рекомендательными технологиями здесь и далее понимаются
        информационные технологии предоставления информации на основе сбора, систематизации и анализа сведений,
        относящихся к предпочтениям пользователей сети «Интернет», находящихся на территории Российской Федерации.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>КАКИЕ ФАЙЛЫ СOOKIE
        ИСПОЛЬЗУЮТСЯ</h2>
      <p style={{ margin: '10px 0' }}>
        Компания использует различные типы файлов cookie. Основная задача в их использовании — это обеспечить удобство
        вашей работы с Сайтом, поддерживать высокий уровень безопасности в их работе, вести подсчет аудитории, помогать
        в проведении оплат, переходах по страницам и прочее. Все файлы cookie Компании относятся к одному из следующих
        типов:
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>
          <strong>Технические файлы cookie</strong>, которые необходимы для правильной работы и предоставления полного
          функционала Сайта.
        </li>
        <li style={{ margin: '10px 0' }}>
          <strong>Аналитические и маркетинговые файлы cookie</strong>, которые позволяют:
          <ul style={{ listStyleType: 'circle', margin: '10px 0 10px 40px' }}>
            <li style={{ margin: '10px 0' }}>распознавать пользователей Сайта, подсчитывать их количество и собирать
              информацию, например, о произведенных операциях, посещенных страницах и просмотрах на Сайте;
            </li>
            <li style={{ margin: '10px 0' }}>идентифицировать ваше аппаратное или программное обеспечение, например, тип
              браузера или устройство;
            </li>
            <li style={{ margin: '10px 0' }}>собирать информацию о том, как вы взаимодействуете с Сайтом, например,
              приобретены ли услуга или продукт;
            </li>
            <li style={{ margin: '10px 0' }}>обеспечивать релевантность рекламы, повышать эффективность рекламных
              кампаний.
            </li>
          </ul>
        </li>
        <li style={{ margin: '10px 0' }}>
          Прочие файлы cookie выполняют различные служебные функции и делают удобнее работу с Сайтом и его функционалом.
          Эти файлы помогают запомнить состояние страницы, выбрать регион, сохранить персональные предпочтения и прочее.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>
        Компания может применять веб-маяки (пиксельные теги) для доступа к файлам cookie, ранее размещенным на вашем
        устройстве. Их использование необходимо для анализа ваших действий при работе с Сайтом путем доступа и
        использования файлов cookie, хранящихся на Вашем устройстве, для сбора статистики о работе Сайта.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания может использовать на Сайте инструмент веб-аналитики Яндекс.Метрика, который позволяет собирать
        обезличенную информацию об источниках трафика, посещаемости Сайта и оценивать эффективность рекламы.
      </p>
      <p style={{ margin: '10px 0' }}>
        Для учета посетителей с помощью сервиса Яндекс.Метрика используются анонимные идентификаторы браузеров, которые
        сохраняются в файлах cookie. Подробнее о файлах cookie Яндекс.Метрики можно прочитать на странице
        <a href="https://yandex.ru/support/metrica/general/cookie-usage.html" target="_blank"
           rel="noopener noreferrer">https://yandex.ru/support/metrica/general/cookie-usage.html</a>.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания также может использовать Инструмент веб-аналитики Google Analytics, который позволяет собирать
        обезличенную информацию об источниках трафика, посещаемости сайта и оценивать эффективность рекламы. Для учета
        посетителей Google Analytics использует анонимные идентификаторы браузеров, которые сохраняются в файлах cookie.
        Подробнее о файлах cookie Google Analytics можно прочитать на странице
        <a href="https://policies.google.com/technologies/partner-sites?hl=en-US" target="_blank"
           rel="noopener noreferrer">https://policies.google.com/technologies/partner-sites?hl=en-US</a>.
      </p>
      <p style={{ margin: '10px 0' }}>
        Отключение некоторых указанных типов файлов cookie может привести к тому, что использование отдельных разделов
        или функций Сайта станет невозможным.
      </p>
      <p style={{ margin: '10px 0' }}>
        Доступ к разделам Сайта, для которых нужна авторизация, обеспечивается техническими файлами cookie, и без их
        использования Сайт может работать некорректно.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>РЕКОМЕНДАТЕЛЬНЫЕ
        ТЕХНОЛОГИИ</h2>
      <p style={{ margin: '10px 0' }}>
        Для алгоритмических вычислений Компания может использовать данные, полученные от пользователей Сайта, а также
        техническую информацию, собираемую автоматически, в том числе информацию о том, как пользователь пользуется
        Сайтом и информацию об устройстве пользователя, в том числе (если применимо):
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>данные о действиях пользователя на Сайте;</li>
        <li style={{ margin: '10px 0' }}>данные о запросах пользователя на Сайте;</li>
        <li style={{ margin: '10px 0' }}>IP адрес;</li>
        <li style={{ margin: '10px 0' }}>файлы cookies;</li>
        <li style={{ margin: '10px 0' }}>длительность пользовательской сессии;</li>
        <li style={{ margin: '10px 0' }}>точки входа (сторонние сайты, с которых пользователь по ссылке переходит на
          сайт);
        </li>
        <li style={{ margin: '10px 0' }}>точки выхода (ссылки на сайте, по которым пользователь переходит на сторонние
          сайты);
        </li>
        <li style={{ margin: '10px 0' }}>страна пользователя;</li>
        <li style={{ margin: '10px 0' }}>геопозиция;</li>
        <li style={{ margin: '10px 0' }}>регион пользователя;</li>
        <li style={{ margin: '10px 0' }}>часовой пояс, установленный на устройстве пользователя;</li>
        <li style={{ margin: '10px 0' }}>провайдер пользователя;</li>
        <li style={{ margin: '10px 0' }}>браузер пользователя;</li>
        <li style={{ margin: '10px 0' }}>ОС пользователя;</li>
        <li style={{ margin: '10px 0' }}>информация об устройстве пользователя;</li>
        <li style={{ margin: '10px 0' }}>параметры экрана (разрешение, глубина цветности, параметры размещения страницы
          на экране);
        </li>
        <li style={{ margin: '10px 0' }}>дата и время посещения сайта;</li>
        <li style={{ margin: '10px 0' }}>источник перехода (UTM метка);</li>
        <li style={{ margin: '10px 0' }}>данные, содержащиеся в Личном кабинете пользователя, зарегистрированного на
          Сайте;
        </li>
        <li style={{ margin: '10px 0' }}>данные сетевого трафика.</li>
      </ul>
      <p style={{ margin: '10px 0' }}>
        Компания использует рекомендательные технологии для формирования персональных и неперсональных подборок на
        Сайте, обработки запросов пользователей Сайта при поиске услуг в целях быстрого предварительного отбора услуг,
        из которых пользователь Сайта может выбрать наиболее подходящие.
      </p>
      <p style={{ margin: '10px 0' }}>
        Алгоритмы Сайта применяются с целью подобрать для пользователя Сайта наиболее релевантные услуги на основании
        его действий на Сайте.
      </p>
      <p style={{ margin: '10px 0' }}>
        Процесс работы рекомендательных технологий Компании заключается в следующем:
      </p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>формируется набор данных о последней активности пользователя;</li>
        <li style={{ margin: '10px 0' }}>на основании этого набора делается предсказание его последующих действий
          набором алгоритмов;
        </li>
        <li style={{ margin: '10px 0' }}>результаты работы нескольких алгоритмов объединяются и могут формировать
          рекомендации, подходящие пользователю;
        </li>
        <li style={{ margin: '10px 0' }}>на основании схожести характеристик услуг и информации о пользователе
          отобранные рекомендации могут досортировываться и сохраняться для выдачи пользователю;
        </li>
        <li style={{ margin: '10px 0' }}>на этапе выдачи услуг заранее рассчитанные рекомендации перемешиваются в
          соответствии с бизнес-правилами.
        </li>
      </ul>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ХРАНЕНИЕ ФАЙЛОВ COOKIE НА
        ВАШЕМ УСТРОЙСТВЕ</h2>
      <p style={{ margin: '10px 0' }}>
        Компания использует сессионные файлы cookie, чтобы Вам было удобнее работать с Сайтом. Например, могут
        запоминаться данные Вашей авторизации на Сайте и при переходе в другую доменную зону уже не нужно заново
        проходить процесс авторизации. Срок действия сессионных файлов cookie истекает в конце сессии (когда вы
        закрываете страницу или окно браузера).
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания также может использовать файлы cookie, которые сохраняются в течение более длительного периода,
        например, чтобы запомнить ваши предпочтения на Сайте, такие как язык или местоположение.
      </p>
      <p style={{ margin: '10px 0' }}>
        Срок хранения данных зависит от типа файлов cookie. Файлы cookie будут автоматически удалены после того, как
        выполнят свою задачу.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания использует информацию, содержащуюся в файлах cookie, в течение сроков и на условиях, указанных в
        настоящей Политике, а также в Политике конфиденциальности.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>УПРАВЛЕНИЕ COOKIE
        ФАЙЛАМИ</h2>
      <p style={{ margin: '10px 0' }}>
        При первом посещении Сайта во всплывающем окне (либо с помощью другого технического решения) Вам может быть
        предложено принять типы файлов cookie, которые будут записаны на вашем устройстве.
      </p>
      <p style={{ margin: '10px 0' }}>
        При этом технические файлы cookie устанавливаются автоматически при загрузке страницы, если иное не указано в
        настройках браузера.
      </p>
      <p style={{ margin: '10px 0' }}>
        Если Вы одобрили использование файлов cookie, но потом захотели изменить свое решение, то сделать это можно
        самостоятельно, удалив сохраненные файлы в Вашем браузере.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания не требует обязательного согласия на установку файлов cookie на ваше устройство при использовании
        Сайта.
      </p>
      <p style={{ margin: '10px 0' }}>
        Если вы не хотите, чтобы файлы cookie сохранялись на вашем устройстве, отключите эту опцию в настройках
        браузера.
      </p>
      <p style={{ margin: '10px 0' }}>
        Сохраненные файлы cookie также можно удалить в любое время в системных настройках браузера.
      </p>
      <p style={{ margin: '10px 0' }}>
        Вы можете изменить настройки браузера, чтобы принимать или отклонять по умолчанию все файлы cookie либо файлы
        cookie с определенных сайтов, включая Сайт.
      </p>
      <p style={{ margin: '10px 0' }}>
        Если Вы одобрили использование файлов cookie на одной из страниц Сайта, это будет означать, что такое согласие
        дано вами для использования файлов cookie на всех страницах Сайта, включая Личный кабинет.
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ЗАКЛЮЧИТЕЛЬНЫЕ
        ПОЛОЖЕНИЯ</h2>
      <p style={{ margin: '10px 0' }}>
        Информация, которая собрана с помощью файлов cookie, размещенных на Вашем устройстве, хранится в соответствии с
        применимым законодательством согласно условиям настоящей Политики и Политики конфиденциальности.
      </p>
      <p style={{ margin: '10px 0' }}>
        Информация, собранная с помощью файлов cookie, размещенных на Вашем устройстве, может быть передана и доступна
        Компании и/или партнерам на условиях Политики конфиденциальности.
      </p>
      <p style={{ margin: '10px 0' }}>
        Использование вне Сайта собранной информации может быть предметом отдельных пользовательских соглашений,
        доступных на Сайте.
      </p>
      <p style={{ margin: '10px 0' }}>
        Компания может также предоставить вам возможность отказаться от персонализации рекламы в соответствии с
        законодательством и применимыми правилами.
      </p>
      <p style={{ margin: '10px 0' }}>
        Дата публикации редакции настоящей Политики: <span>___</span>.07.2024 г.
      </p>
    </DocumentModal>
  )
}
