import React from 'react'
import { DocumentModal } from 'components/DocumentModal'

interface DocPrivacyPolicyModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DocPrivacyPolicyModal: React.FC<DocPrivacyPolicyModalProps> = ({ isOpen, onClose }) => {
  return (
    <DocumentModal isOpen={isOpen} onClose={onClose}>
      <p style={{ margin: '10px 0' }}>
        <strong>ИСПОЛЬЗУЯ ПРЕДОСТАВЛЯЕМЫЕ В РАМКАХ НАСТОЯЩЕГО САЙТА УСЛУГИ, Я БЕЗУСЛОВНО ПОДТВЕРЖДАЮ, ЧТО ОЗНАКОМЛЕН,
          СОГЛАСЕН И ПРИНИМАЮ УСЛОВИЯ НАСТОЯЩЕЙ ПОЛИТИКИ</strong>
      </p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ОБЩИЕ ПОЛОЖЕНИЯ</h2>
      <p style={{ margin: '10px 0' }}>
        1.1. Политика конфиденциальности и обработки персональных данных пользователей Сайта <span>___</span> (далее –
        Политика) разработана с целью заявления о положениях, в соответствии с которыми ООО «<span>___</span>» (далее –
        Правообладатель) обрабатывает персональные данные пользователей Сайта <span>___</span> (далее – Сайт).
        Владельцем исключительных имущественных прав на Сайт является Правообладатель.
      </p>
      <p style={{ margin: '10px 0' }}>
        1.2. Порядок использования Сайта определен в Оферте Сайта (далее – Оферта). С Пользовательским соглашением можно
        ознакомиться по ссылке: <span>___</span>. Пользователь обязуется ознакомиться с Пользовательским соглашением и
        соблюдать положения указанного документа, нарушение которого может повлечь нарушение прав и законных интересов
        Правообладателя и третьих лиц.
      </p>
      <p style={{ margin: '10px 0' }}>
        1.3. Обработка персональных данных Правообладателем осуществляется в соответствии с требованиями Федерального
        закона Российской Федерации от 27.07.2006 № 152-ФЗ «О персональных данных» и иными нормативными правовыми актами
        Российской Федерации, регулирующими правоотношения в сфере обработки персональных данных.
      </p>
      <p style={{ margin: '10px 0' }}>
        1.4. В случае, если субъект персональных данных возражает против обработки персональных данных Правообладателем
        в соответствии с настоящей Политикой, субъект персональных данных вправе отказаться от использования Сайта и/или
        направить соответствующее обращение в адрес Правообладателя. В таком случае предоставление функционала Сайта,
        включая регистрацию Личного кабинета, осуществляться не будет.
      </p>
      <p style={{ margin: '10px 0' }}>1.5. Термины, определения и понятия, используемые в Политике:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}><strong>«Сайт»</strong> – сайт, предоставляющий сервис «<span>___</span>»,
          расположенный по адресу <span>___</span>, позволяющий Пользователям приобретать услуги, размещенные на Сайте;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Компоненты»</strong> – части Сайта, специализированные на
          предоставлении Пользователю отдельных сервисов и (или) информации;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Пользователь»</strong> – любое лицо, получающее доступ к Сайту и (или)
          любой информации, размещенной на Сайте;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Персональные данные»</strong> – любая информация, относящаяся к
          определенному или определяемому на основании такой информации физическому лицу (субъекту персональных данных),
          в том числе его фамилия, имя, отчество, адрес электронной почты, телефонный номер, дата рождения, возраст (при
          условии их предоставления Пользователем);
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Услуги»</strong> – услуги, размещаемые на Сайте для целей продажи
          покупателям Услуг, являющимся пользователями Сайта;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Блокирование персональных данных»</strong> – временное прекращение
          обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных
          данных);
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Защита персональных данных»</strong> – комплекс мероприятий
          технического, организационного и организационно-технического характера, направленных на защиту сведений,
          относящихся к определенному или определяемому на основании такой информации субъекту Персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Оператор»</strong> – Правообладатель, самостоятельно или совместно с
          другими лицами, организующий и (или) осуществляющий обработку персональных данных, а также определяющий цели
          обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции),
          совершаемые с персональными данными;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Конфиденциальность персональных данных»</strong> – обязательное для
          выполнения Оператором или иным лицом, получившим доступ к персональным данным, требование не допускать
          раскрытия персональных данных третьим лицам и их распространение для целей, отличных от установленных в
          настоящей Политике, без согласия субъекта персональных данных или наличия иного законного основания;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Обезличивание персональных данных»</strong> – действия, в результате
          которых становится невозможным без использования дополнительной информации определить принадлежность
          персональных данных конкретному субъекту персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Обработка персональных данных»</strong> – любое действие (операция)
          или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования
          таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
          (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных данных, если иное прямо не указывается в
          Оферте и (или) в настоящей Политике;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Ответственный за обработку персональных данных»</strong> – работник
          Правообладателя, осуществляющий обеспечение безопасности, защиты и соблюдения требований применимого
          законодательства при обработке персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Предоставление персональных данных»</strong> – действия, направленные
          на раскрытие персональных данных государственным органам, осуществляющим проверку персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Уничтожение персональных данных»</strong> – действия, в результате
          которых становится невозможным восстановить содержание персональных данных в информационной системе
          персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}><strong>«Общедоступные персональные данные»</strong> — персональные данные,
          доступ к которым предоставлен неограниченному кругу лиц с согласия субъекта или на которые в соответствии с
          федеральными законами не распространяется требование соблюдения конфиденциальности;
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>1.6. Политика касается как автоматизированной, так и неавтоматизированной
        обработки персональных данных.</p>
      <p style={{ margin: '10px 0' }}>1.7. Политика подлежит пересмотру и, при необходимости, актуализации в случае
        изменения функционала Сайта.</p>
      <p style={{ margin: '10px 0' }}>1.8. Пользователь соглашается принимать отслеживать и принимать изменения в
        настоящую Политику с даты внесения изменений.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ПРИНЦИПЫ ОБРАБОТКИ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>2.1. Персональные данные собираются и обрабатываются на основе справедливости,
        целесообразности и законности.</p>
      <p style={{ margin: '10px 0' }}>2.2. Персональные данные хранятся для заранее определенных целей и не используются
        иным образом, несовместимым с этими целями, указанными в настоящей Политике.</p>
      <p style={{ margin: '10px 0' }}>2.3. Персональные данные являются адекватными, относящимися к делу и не
        чрезмерными для целей их хранения.</p>
      <p style={{ margin: '10px 0' }}>2.4. Не допускается обработка персональных данных, несовместимая с целями сбора
        таких данных.</p>
      <p style={{ margin: '10px 0' }}>2.5. Не допускается объединение баз данных, содержащих персональные данные,
        обработка которых осуществляется в целях, несовместимых между собой.</p>
      <p style={{ margin: '10px 0' }}>2.6. Допускается обработка исключительно тех персональных данных, которые отвечают
        целям их обработки.</p>
      <p style={{ margin: '10px 0' }}>2.7. Содержание и объем обрабатываемых персональных данных должны соответствовать
        заявленным целям обработки.</p>
      <p style={{ margin: '10px 0' }}>2.8. Не допускается обработка персональных данных, излишних по отношению к
        заявленным целям обработки.</p>
      <p style={{ margin: '10px 0' }}>2.9. При обработке персональных данных должны быть обеспечены точность
        персональных данных, их достаточность, актуальность по отношению к целям обработки персональных данных. Неполные
        или неточные данные должны быть удалены или уточнены.</p>
      <p style={{ margin: '10px 0' }}>2.10. Хранение персональных данных должно осуществляться в форме, позволяющей
        определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если
        срок хранения персональных данных не установлен законом, договором, стороной которого, выгодоприобретателем или
        поручителем, по которому является субъект персональных данных.</p>
      <p style={{ margin: '10px 0' }}>2.11. По достижении целей обработки или в случае утраты необходимости в достижении
        этих целей, по законному требованию субъекта персональных данных или уполномоченных органов судебной и
        исполнительной власти персональных данных должны быть уничтожены или обезличены, если иное не предусмотрено
        применимым законодательством.</p>
      <p style={{ margin: '10px 0' }}>2.12. Для целей защиты персональных данных Правообладатель использует
        локализованные технологии.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ВОЗМОЖНЫЕ ВИДЫ ПОЛУЧАЕМЫХ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>3.1. Сайт может получать доступ к следующей информации, если применимо (в
        некоторых случаях Пользователь может предоставлять указанные ниже данные):</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Фамилия, имя, отчество Пользователя (для целей оформления заказов и доставки
          Услуг);
        </li>
        <li style={{ margin: '10px 0' }}>Дата рождения (если применимо, с целью определения возраста Пользователя);</li>
        <li style={{ margin: '10px 0' }}>Адрес электронной почты (для привязки Личного кабинета Пользователя и входа в
          Личный кабинет (далее также – «аккаунт»);
        </li>
        <li style={{ margin: '10px 0' }}>Адрес Пользователя (для целей доставки документов);</li>
        <li style={{ margin: '10px 0' }}>Мобильный телефон Пользователя (для целей подписания документов простой
          электронной подписью, связи с пользователем по вопросам оказания Услуг, восстановления доступа Пользователя к
          аккаунту Пользователя);
        </li>
        <li style={{ margin: '10px 0' }}>Запись электронной переписки на Сайте включая Личный кабинет (если применимо,
          для целей подтверждения договоренностей и иной информации касательно Услуг);
        </li>
        <li style={{ margin: '10px 0' }}>Реквизиты электронных средств платежа (номер, срок действия, код CVV/CVC) (для
          целей оплаты Услуг);
        </li>
        <li style={{ margin: '10px 0' }}>Иная разумно необходимая информация, необходимая для целей оформления и
          оказания Услуг.
        </li>
      </ul>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ
        ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>4.1. Правообладатель обрабатывает персональные данные для целей предоставления
        Пользователю возможности использования Сайта, а также в целях:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Продажи и сопровождения в продаже Услуг посредством Сайта;</li>
        <li style={{ margin: '10px 0' }}>Оказания консультационных, информационных и посреднических услуг, включая
          заключение договоров с агентами в целях оказания Услуг;
        </li>
        <li style={{ margin: '10px 0' }}>Продвижения и последующей продажи Услуг;</li>
        <li style={{ margin: '10px 0' }}>Размещения информации об Услугах на Сайте;</li>
        <li style={{ margin: '10px 0' }}>Размещения предложений по условиям оказания Услуг, поставки (если применимо),
          оказания информационных услуг;
        </li>
        <li style={{ margin: '10px 0' }}>Размещения отзывов (если применимо);</li>
        <li style={{ margin: '10px 0' }}>Размещения ссылок на социальные сети Пользователя (если применимо);</li>
        <li style={{ margin: '10px 0' }}>Получения статистических данных;</li>
        <li style={{ margin: '10px 0' }}>Предоставления сведений уведомительного или маркетингового характера, в том
          числе о новых услугах, проводимых акциях, мероприятиях (по которым имеется предварительное согласие
          Пользователя на их получение);
        </li>
        <li style={{ margin: '10px 0' }}>Обработки запросов и(или) жалоб Пользователей;</li>
        <li style={{ margin: '10px 0' }}>Регистрация и использование личного кабинета для совершения заказов на Сайте, а
          также для получения информационных и рекламных рассылок;
        </li>
        <li style={{ margin: '10px 0' }}>Использование личного кабинета зарегистрированным Пользователем на Сайте, путем
          заполнения информации о себе;
        </li>
        <li style={{ margin: '10px 0' }}>Оформление заказов (в том числе оплаты и доставки заказов), отслеживания
          статуса заказа, написания отзывов об услугах, составления обращений по вопросам работы Сайта через специальную
          форму в личном кабинете зарегистрированного Пользователя;
        </li>
        <li style={{ margin: '10px 0' }}>В целях исполнения требований законодательства Российской Федерации.</li>
      </ul>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>5.1. Правообладатель обрабатывает персональные данные как с использованием средств
        автоматизации, так и без использования таких средств (в случае обращения Пользователя к Правообладателю в
        нештатных ситуациях).</p>
      <p style={{ margin: '10px 0' }}>5.2. Правообладатель не предоставляет и не раскрывает персональные данные третьим
        лицам без согласия субъекта данных, за исключением случаев, предусмотренных законом.</p>
      <p style={{ margin: '10px 0' }}>5.3. Согласие субъекта персональных данных на обработку его персональных данных
        может быть дано субъектом персональных данных или его представителем в любой форме, позволяющей подтвердить факт
        его получения, в частности, путем согласия с положениями настоящей Политики при начале пользования Сайтом.</p>
      <p style={{ margin: '10px 0' }}>5.4. Правообладатель принимает организационные и технические меры по защите
        персональных данных.</p>
      <p style={{ margin: '10px 0' }}>5.5. Условием прекращения обработки персональных данных может являться достижение
        целей обработки персональных данных и (или) отзыв согласия субъекта персональных данных на их обработку.</p>
      <p style={{ margin: '10px 0' }}>5.6. Персональные данные, в том числе, но не ограничиваясь, переписки,
        использующиеся в рамках Сайта, не хранятся у Правообладателя и не передаются третьим лицам за исключением
        уполномоченных агентов Правообладателя.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ПРОДОЛЖИТЕЛЬНОСТЬ ХРАНЕНИЯ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>6.1. Персональные данные хранятся в пределах сроков, определенных применимым
        законодательством.</p>
      <p style={{ margin: '10px 0' }}>6.2. Данные, выбранные или введенные Пользователем, хранятся на Сайте, а также
        могут храниться посредством локализованых в соответствии с законодательством облачных Сайтов на серверах у
        Правообладателя.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ВЗАИМОДЕЙСТВИЕ С СУБЪЕКТАМИ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>7.1. Субъекту персональных данных предоставляется возможность:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Знать об обработке Правообладателем его персональных данных, знать основные
          цели их обработки;
        </li>
        <li style={{ margin: '10px 0' }}>Получить через разумный промежуток времени и без чрезмерной задержки или
          чрезмерных расходов подтверждение обработки Правообладателем его персональных данных, а также получить
          персональные данные в доступной для понимания форме, если у Правообладателя нет правовых препятствий для
          предоставления такой информации;
        </li>
        <li style={{ margin: '10px 0' }}>Добиваться исправления неточных персональных данных и добиваться уничтожения
          персональных данных, если их обработка не отвечает основаниям обработки персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}>Прибегать к средствам правовой защиты в случае невыполнения просьбы о
          подтверждении обработки, изменении или уничтожении персональных данных.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>7.2. Субъект персональных данных может реализовать указанные возможности путем
        личного обращения или путем направления запроса по следующему адресу электронной почты: <span>___</span></p>
      <p style={{ margin: '10px 0' }}>7.3. Запрос Пользователя (или его представителя) должен содержать:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Реквизиты документа, удостоверяющего личность Пользователя;</li>
        <li style={{ margin: '10px 0' }}>Сведения, подтверждающие факт обработки Правообладателем персональных данных
          Пользователя;
        </li>
        <li style={{ margin: '10px 0' }}>Запрос пользователя;</li>
        <li style={{ margin: '10px 0' }}>Подпись Пользователя или его представителя.</li>
      </ul>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ПРАВА СУБЪЕКТОВ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>8.1. Субъект персональных данных имеет право на получение информации, касающейся
        обработки его персональных данных, в том числе содержащей:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Подтверждение факта обработки персональных данных;</li>
        <li style={{ margin: '10px 0' }}>Правовые основания и цели обработки персональных данных;</li>
        <li style={{ margin: '10px 0' }}>Применяемые Правообладателем способы обработки персональных данных;</li>
        <li style={{ margin: '10px 0' }}>Наименование и место нахождения Правообладателя, сведения о лицах (за
          исключением работников), которые имеют доступ к персональным данным или которым могут быть раскрыты
          персональные данные на основании договора или на основании законодательных требований;
        </li>
        <li style={{ margin: '10px 0' }}>Обрабатываемые персональные данные, относящиеся к соответствующему субъекту
          персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен
          соответствующим законом;
        </li>
        <li style={{ margin: '10px 0' }}>Сроки обработки персональных данных, в том числе сроки их хранения;</li>
        <li style={{ margin: '10px 0' }}>Порядок осуществления субъектом персональных данных прав, предусмотренных
          соответствующим законом;
        </li>
        <li style={{ margin: '10px 0' }}>Информацию об осуществленной или о предполагаемой трансграничной передаче
          персональных данных;
        </li>
        <li style={{ margin: '10px 0' }}>Наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку
          персональных данных по поручению Правообладателя, если обработка поручена или будет поручена такому лицу;
        </li>
        <li style={{ margin: '10px 0' }}>Иные сведения, предусмотренные Федеральным законом «О персональных данных» или
          другими применимыми законами.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>8.2. Субъект персональных данных вправе требовать от Правообладателя, путем
        направления письменного требования, уточнения его персональных данных, их блокирования или уничтожения в случае,
        если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются
        необходимыми для заявленной цели обработки, а также принимать предусмотренные соответствующими законами меры по
        защите своих прав.</p>
      <p style={{ margin: '10px 0' }}>8.3. Если Пользователь считает, что Правообладатель осуществляет обработку его
        персональных данных с нарушением требований применимого законодательства в сфере персональных данных или иным
        образом нарушает его права и свободы, Пользователь вправе направить претензию Правообладателю и, в случае
        бездействия Правообладателя в разумные сроки, обжаловать действия или бездействие Правообладателя в
        соответствующий орган по защите прав субъектов персональных данных или в судебном порядке.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>КОНФИДЕНЦИАЛЬНОСТЬ
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>9.1. Информация, относящаяся к персональным данным, ставшая известной
        Правообладателю, является конфиденциальной информацией и охраняется законом.</p>
      <p style={{ margin: '10px 0' }}>9.2. Работники Правообладателя и иные лица, получившие доступ к обрабатываемым
        персональным данным, подписали обязательство о неразглашении конфиденциальной информации, а также предупреждены
        о возможной дисциплинарной, административной, гражданско-правовой и уголовной ответственности в случае нарушения
        норм и требований действующего законодательства в области обработки персональных данных.</p>
      <p style={{ margin: '10px 0' }}>9.3. Договоры Правообладателя с контрагентами содержат условия конфиденциальности
        передаваемых и получаемых персональных данных, в том числе с лицами, привлекаемыми для обработки персональных
        данных.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>МЕРЫ ПО ОБЕСПЕЧЕНИЮ
        БЕЗОПАСНОСТИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>10.1. Для обеспечения безопасности обрабатываемых персональных данных
        Правообладатель принимает необходимые правовые, организационные, технические меры защиты.</p>
      <p style={{ margin: '10px 0' }}>10.2. Система защиты персональных данных Правообладателя базируется на
        принципах:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Централизованность – система защиты данных должна централизованно
          управляться;
        </li>
        <li style={{ margin: '10px 0' }}>Своевременность – меры обеспечения безопасности персональных данных,
          применяемые в рамках системы защиты, должны быть своевременными;
        </li>
        <li style={{ margin: '10px 0' }}>Целенаправленность – меры обеспечения безопасности персональных данных,
          применяемые в рамках системы защиты, должны иметь четкие цели, на достижение которых они направлены;
        </li>
        <li style={{ margin: '10px 0' }}>Комплексность – система защиты должна включать комплекс мер, направленных на
          обеспечение безопасности персональных данных, дополняющих и поддерживающих друг друга;
        </li>
        <li style={{ margin: '10px 0' }}>Превентивность – меры обеспечения безопасности персональных данных, применяемые
          в рамках системы защиты, должны носить предупреждающий характер;
        </li>
        <li style={{ margin: '10px 0' }}>Надежность – система защиты персональных данных должна обеспечивать достаточные
          гарантии Правообладателя в том, что обрабатываемые персональных данных защищены надлежащим образом.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>10.3. В целях осуществления внутреннего контроля соответствия обработки
        персональных данных установленным Правообладателем требованиям организовано проведение периодических проверок
        условий обработки персональных данных.</p>
      <p style={{ margin: '10px 0' }}>10.4. Организация при защите персональных данных Пользователей принимает все
        необходимые организационно-распорядительные, юридические и технические меры, в том числе:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Антивирусная защита;</li>
        <li style={{ margin: '10px 0' }}>Анализ защищённости;</li>
        <li style={{ margin: '10px 0' }}>Обнаружение и предотвращение вторжений;</li>
        <li style={{ margin: '10px 0' }}>Управления доступом;</li>
        <li style={{ margin: '10px 0' }}>Регистрация и учет;</li>
        <li style={{ margin: '10px 0' }}>Обеспечение целостности;</li>
        <li style={{ margin: '10px 0' }}>Организация нормативно-методических локальных актов, регулирующих защиту
          персональных данных Пользователей.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>10.5. Процедура оформления доступа к персональным данным Пользователя включает в
        себя:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Ознакомление сотрудника под роспись с настоящим Положением. При наличии иных
          нормативных актов (приказы, распоряжения, инструкции и т.п.), регулирующих обработку и защиту персональных
          данных Пользователя, с данными актами также производится ознакомление под роспись.
        </li>
        <li style={{ margin: '10px 0' }}>Истребование с сотрудника (за исключением Генерального директора) письменного
          обязательства о соблюдении конфиденциальности персональных данных Пользователей и соблюдении правил их
          обработки в соответствии с внутренними локальными актами Правообладателя, регулирующих вопросы обеспечения
          безопасности конфиденциальной информации.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>10.6. Сотрудник Организации, имеющий доступ к персональным данным Пользователей в
        связи с исполнением трудовых обязанностей:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Обеспечивает хранение информации, содержащей персональные данные Пользователя,
          исключающее доступ к ним третьих лиц.
        </li>
        <li style={{ margin: '10px 0' }}>В отсутствие сотрудника на его рабочем месте не должно быть документов,
          содержащих персональные данные Пользователей.
        </li>
        <li style={{ margin: '10px 0' }}>При уходе в отпуск, во время служебной командировки и в иных случаях
          длительного отсутствия сотрудника на своем рабочем месте, он обязан передать документы и иные носители,
          содержащие персональные данные Пользователей лицу, на которое локальным актом Правообладателя (приказом,
          распоряжением) будет возложено исполнение его трудовых обязанностей.
        </li>
        <li style={{ margin: '10px 0' }}>В случае если такое лицо не назначено, то документы и иные носители, содержащие
          персональные данные Пользователей, передаются другому сотруднику, имеющему доступ к персональным данным
          Пользователей по указанию Генерального директора Правообладателя.
        </li>
        <li style={{ margin: '10px 0' }}>При увольнении сотрудника, имеющего доступ к персональным данным Пользователей,
          документы и иные носители, содержащие персональные данные Пользователей, передаются другому сотруднику,
          имеющему доступ к персональным данным Пользователей по указанию Генерального директора.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>10.7. Ответственный за обработку персональных данных обеспечивает:</p>
      <ul style={{ listStyleType: 'disc', margin: '10px 0 10px 40px' }}>
        <li style={{ margin: '10px 0' }}>Ознакомление сотрудников под роспись с настоящей Политикой.</li>
        <li style={{ margin: '10px 0' }}>Истребование с сотрудников письменного обязательства о соблюдении
          конфиденциальности персональных данных Пользователей и соблюдении правил их обработки.
        </li>
        <li style={{ margin: '10px 0' }}>Общий контроль за соблюдением сотрудниками Правообладателя мер по защите
          персональных данных Пользователя.
        </li>
      </ul>
      <p style={{ margin: '10px 0' }}>10.8. Защита персональных данных Пользователей, хранящихся в электронных базах
        данных Организации от несанкционированного доступа, искажения и уничтожения информации, а также от иных
        неправомерных действий обеспечивается системным администратором.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>МЕСТОНАХОЖДЕНИЕ БАЗ ДАННЫХ
        ИНФОРМАЦИИ, СОДЕРЖАЩИХ ПЕРСОНАЛЬНЫЕ ДАННЫЕ</h2>
      <p style={{ margin: '10px 0' }}>11.1. Базы данных информации, содержащие персональные данные российских граждан и
        организаций, размещаются на территории Российской Федерации.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ТРАНСГРАНИЧНАЯ ПЕРЕДАЧА
        ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>12.1. Персональные данные не передаются каким-либо юридическим или физическим
        лицам, но могут быть переданы при условии соблюдения норм применимого законодательства и настоящей Политики.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ОТВЕТСТВЕННОСТЬ ЗА
        НАРУШЕНИЕ НОРМ, РЕГУЛИРУЮЩИХ ОБРАБОТКУ И ЗАЩИТУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
      <p style={{ margin: '10px 0' }}>13.1. Работники Правообладателя, виновные в нарушении норм, регулирующих
        получение, обработку и защиту персональных данных, несут дисциплинарную, административную, гражданско-правовую
        или уголовную ответственность в соответствии с действующим законодательством Российской Федерации и внутренними
        локальными актами Правообладателя.</p>

      <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', color: '#333' }}>ДОПОЛНИТЕЛЬНЫЕ
        ПОЛОЖЕНИЯ</h2>
      <p style={{ margin: '10px 0' }}>14.1. Настоящая Политика вступает в силу с момента ее утверждения генеральным
        директором Правообладателя и действует бессрочно до замены ее новой Политикой.</p>
      <p style={{ margin: '10px 0' }}>14.2. Правообладатель вправе в одностороннем порядке изменять условия
        Политики.</p>
      <p style={{ margin: '10px 0' }}>14.3. Ввиду того, что текст Политики находится в свободном доступе в сети
        «Интернет», субъекту персональных данных (Пользователю) необходимо самостоятельно следить за изменениями,
        вносимыми в Политику.</p>
      <p style={{ margin: '10px 0' }}>14.4. Настоящая Политика распространяется на персональные данные, полученные как
        до, так и после ввода в действие настоящей Политики.</p>
      <p style={{ margin: '10px 0' }}>14.5. Настоящая Политика, а также все изменения к ней, утверждаются
        Правообладателем и вступают в силу со дня ее опубликования на Сайте, как указано в настоящей Политике.</p>
      <p style={{ margin: '10px 0' }}>14.6. С момента вступления в силу настоящей редакции Политики, предыдущую ее
        редакцию считать недействительной.</p>

      <p style={{ margin: '10px 0' }}><strong>Редакция от <span>___</span>.06.2024</strong></p>
    </DocumentModal>
  )
}
