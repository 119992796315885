import React, { useMemo } from 'react'
import { EDocumentType, useUserInvoicePaidMutation } from 'api/graphql'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { UploadButton } from '../../Upload/UploadButton'
import { StepWrapper } from '../../../elements/StepWrapper'
import { getStringOrNull } from 'utils/isEmptyString'
import { Box, Stack } from '@chakra-ui/react'
import { FileItem } from '../../../elements/FileItem'
import { FormLabel } from 'uikit/FormLabel'
import { isEmpty } from 'lodash'

export const InvoicePaymentStep = () => {
  const { orderId, orderData, documentsData } = useOrderData()
  const [userInvoicePaidMutation] =
    useUserInvoicePaidMutation()
  const { refetchOrder } = useRefetchOrder()

  const paymentInvoiceDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.PaymentInvoice
      ),
    [documentsData]
  )

  const contractAppendixDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.ContractAppendix
      ),
    [documentsData]
  )

  const handleFinish = async () => {
    if (!orderId) return
    await userInvoicePaidMutation({
      variables: {
        id: orderId,
      },
    })
    await refetchOrder()
  }

  return (
    <>
      <StepWrapper
        description={getStringOrNull(orderData?.comment) ?? 'Готов счет для оплаты'}
      >
        {!isEmpty(paymentInvoiceDocuments) && (
          <Box>
            <FormLabel>
              Счет
            </FormLabel>
            <Stack spacing="10px">
              {paymentInvoiceDocuments?.map((document) => (
                <FileItem
                  key={document.id}
                  id={document.id}
                  name={document?.fileName}
                  orderId={orderId}
                  cursor="pointer"
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: 'gray.200',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                />
              ))}
            </Stack>
          </Box>
        )}
        {!isEmpty(contractAppendixDocuments) && (
          <Box>
            <FormLabel>
              Приложение к договору
            </FormLabel>
            <Stack spacing="10px">
              {contractAppendixDocuments?.map((document) => (
                <FileItem
                  key={document.id}
                  id={document.id}
                  name={document?.fileName}
                  orderId={orderId}
                  cursor="pointer"
                  backgroundColor="white"
                  _hover={{
                    backgroundColor: 'gray.200',
                  }}
                  _active={{
                    backgroundColor: 'gray.300',
                  }}
                />
              ))}
            </Stack>
          </Box>
        )}
      </StepWrapper>
      <StepWrapper
        description="Документы, подтверждающие оплату"
      >
        <UploadButton
          documentType="PaidInvoice"
          selectLabelButton="Выбрать документы"
          uploadLabelButton="Загрузить и отправить"
          onFinish={handleFinish}
        />
      </StepWrapper>
    </>
  )
}
