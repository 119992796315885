import { Center } from '@chakra-ui/react'
import { useCurrentUser } from 'state/useCurrentUser'
import { Outlet } from 'react-router-dom'

export const ControlLayout = () => {
  const { isControl } = useCurrentUser()

  if (isControl) {
    return (
      <Outlet/>
    )
  }

  return (
    <Center flex={1}>
      Нет доступа
    </Center>
  )
}
