import { Box, Button, Center, Flex, Stack, useToast } from '@chakra-ui/react'
import { Field, Formik, FormikValues } from 'formik'
import { useLoginMutation } from '../../api/graphql'
import { InputGroupForm } from '../../uikit/InputGroup'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { InputMaskForm } from '../../uikit/InputMask'

const formSchema = Yup.object().shape({
  phone: Yup.string().required(''),
  password: Yup.string().required('')
})

export const Login = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const [ loginMutation, { loading: isLoading } ] = useLoginMutation()

  const handleForm = async (values: FormikValues) => {
    try {
      const phoneValue = `${values?.phone?.replace(/\D/g, '')}`
      await loginMutation({
        variables: {
          phone: phoneValue,
          password: values?.password
        }
      })
      navigate('/')
    } catch (e) {
      toast({
        title: `Ошибка авторизации`,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  return (
    <Center flex={1}>
      <Flex
        backgroundColor="white"
        maxW="420px"
        width="100%"
        borderRadius="20px"
        padding="40px 50px 50px"
        borderWidth={1}
      >
        <Stack
          width="100%"
          spacing="30px"
          textAlign="center"
        >
          <Stack>
            <Box fontSize="1.8rem" fontWeight={700}>
              Авторизация
            </Box>
          </Stack>
          <Formik
            validationSchema={formSchema}
            initialValues={{
              phone: '',
              password: ''
            }}
            onSubmit={handleForm}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Stack spacing="24px">
                  <Stack spacing="14px">
                    <Field
                      size="lg"
                      name="phone"
                      placeholder="Телефон"
                      component={InputMaskForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      size="lg"
                      type="password"
                      name="password"
                      placeholder="Пароль"
                      component={InputGroupForm}
                      isDisabled={isLoading}
                    />
                  </Stack>
                  <Stack spacing="14px">
                    <Button
                      size="lg"
                      isLoading={isLoading}
                      type="submit"
                      variant="primary"
                    >
                      Войти
                    </Button>
                    <Button
                      size="lg"
                      variant="secondary"
                      onClick={() => navigate('/registration')}
                    >
                      Зарегистрироваться
                    </Button>
                  </Stack>
                </Stack>
              </form>
            )}
          </Formik>
        </Stack>
      </Flex>
    </Center>
  )
}
