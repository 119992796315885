import React, { useMemo, useState } from 'react'
import { useOrderData, useRefetchOrder } from 'state/useOrder'
import { isEmptyString } from 'utils/isEmptyString'
import { StepWrapper } from '../../../elements/StepWrapper'
import { Box, Button, Divider, HStack, Stack } from '@chakra-ui/react'
import { UploadButton } from '../../Upload/UploadButton'
import { TextArea } from 'uikit/TextArea'
import { EDocumentType, useUserActSentMutation, useUserPaymentToSupplierFailedMutation } from 'api/graphql'
import { isEmpty } from 'lodash'
import { FormLabel } from 'uikit/FormLabel'
import { FileItem } from '../../../elements/FileItem'

export const PaymentToSupplerConfirmedStep = () => {
  const { orderData, orderId, documentsData } = useOrderData()
  const [isSupplierFailed, setIsSupplierFailed] = useState(false)
  const [value, setValue] = useState('')
  const { refetchOrder } = useRefetchOrder()

  const [userPaymentToSupplierFailedMutation, { loading: isFailedLoading }] = useUserPaymentToSupplierFailedMutation()
  const [userActSentMutation, { loading: isActLoading }] = useUserActSentMutation()

  const actDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Act
      ),
    [documentsData]
  )

  const swiftDocuments = useMemo(
    () =>
      documentsData?.filter(
        (document) => document.documentType === EDocumentType.Swift
      ),
    [documentsData]
  )

  const handleFailed = async () => {
    if (!orderId) return
    await userPaymentToSupplierFailedMutation({
      variables: {
        id: orderId,
        comment: value,
      }
    })
    await refetchOrder()
  }

  const handleFinish = async () => {
    if (!orderId) return
    await userActSentMutation({
      variables: {
        id: orderId
      },
    })
    await refetchOrder()
  }

  return (
    <StepWrapper
      description="Средства успешно переведены поставщику. Загрузите подписанный акт и отправьте"
    >
      <Divider borderColor="gray.300" />
      {!isEmpty(actDocuments) && (
        <Box>
          <FormLabel>
            Акты на подпись
          </FormLabel>
          <Stack spacing="10px">
            {actDocuments?.map((document) => (
              <FileItem
                key={document.id}
                id={document.id}
                name={document?.fileName}
                orderId={orderId}
                cursor="pointer"
                backgroundColor="white"
                _hover={{
                  backgroundColor: 'gray.200',
                }}
                _active={{
                  backgroundColor: 'gray.300',
                }}
              />
            ))}
          </Stack>
        </Box>
      )}
      {!isEmpty(swiftDocuments) && (
        <Box>
          <FormLabel>
            SWIFT документы
          </FormLabel>
          <Stack spacing="10px">
            {swiftDocuments?.map((document) => (
              <FileItem
                key={document.id}
                id={document.id}
                name={document?.fileName}
                orderId={orderId}
                cursor="pointer"
                backgroundColor="white"
                _hover={{
                  backgroundColor: 'gray.200',
                }}
                _active={{
                  backgroundColor: 'gray.300',
                }}
              />
            ))}
          </Stack>
        </Box>
      )}
      <Divider borderColor="gray.300" />
      {!isEmptyString(orderData?.comment) && <Box>{orderData?.comment}</Box>}
      {isSupplierFailed ? (
        <>
          <Stack spacing="18px">
            <TextArea
              name="comment"
              value={value}
              placeholder="Напишите что случилось (по желанию)"
              minHeight="100px"
              onChange={(e) => setValue(e.target.value)}
            />
            <HStack spacing="16px">
              <Button
                isLoading={isFailedLoading}
                onClick={handleFailed}
                variant="attention"
              >
                Отправить
              </Button>
              <Button
                isDisabled={isFailedLoading}
                onClick={() => setIsSupplierFailed(false)}
                variant="secondary"
              >
                Отменить
              </Button>
            </HStack>
          </Stack>
        </>
      ) : (
        <Button
          width="fit-content"
          isDisabled={isActLoading}
          onClick={() => setIsSupplierFailed(true)}
          size="sm"
          variant="linkBlue"
        >
          Возникли проблемы при получении средств
        </Button>
      )}
      {!isSupplierFailed && (
        <UploadButton
          documentType="SignedAct"
          selectLabelButton="Выбрать подписанный акт"
          uploadLabelButton="Загрузить и отправить"
          onFinish={handleFinish}
        />
      )}
    </StepWrapper>
  )
}
