import { Icon } from '@chakra-ui/icon'
import { LuFileText } from 'react-icons/lu'
import {
  Box,
  Center,
  Checkbox,
  Grid,
  GridProps,
  IconButton,
} from '@chakra-ui/react'
import { truncate } from 'lodash'
import React from 'react'
import download from 'downloadjs'
import { SmallCloseIcon } from '@chakra-ui/icons'

interface FileContainerProps extends GridProps {
  id?: string
  orderId?: string | null | undefined
  name: string | null | undefined
  isSelecting?: boolean
  isActive?: boolean
  onRemove?: () => void
  onCheckboxClick?: (id: string) => void
}

export const FileItem: React.FC<FileContainerProps> = ({
  id,
  orderId,
  name,
  isSelecting,
  isActive,
  onRemove,
  onCheckboxClick,
  ...props
}) => {
  const handleClick = () => {
    if (!id || !orderId) return
    download(`/api/files/order/${orderId}/documents/${id}`)
  }

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (!id || !onCheckboxClick) return
    onCheckboxClick(id)
  }

  return (
    <Grid
      role="group"
      onClick={handleClick}
      templateColumns={onRemove ? '17px 1fr 24px' : '20px 1fr'}
      borderRadius="8px"
      gap="10px"
      height="40px"
      backgroundColor="gray.100"
      alignItems="center"
      padding="4px 10px 4px 10px"
      transition="0.2s"
      {...props}
    >
      <Center position="relative" boxSize="24px" onClick={handleCheckboxClick}>
        {isSelecting && (
          <Checkbox
            isChecked={isActive}
            position="absolute"
            backgroundColor="white"
            transition="0.2s"
            opacity={isActive ? 1 : 0}
            borderColor="gray.400"
            borderRadius="4px"
            colorScheme="purple"
            _groupHover={{
              opacity: 1,
            }}
          />
        )}
        <Icon
          as={LuFileText}
          boxSize="17px"
          transition="0.2s"
          opacity={isActive ? 0 : 1}
          {...isSelecting && {
            _groupHover: {
              opacity: 0
            }
          }}
        />
      </Center>
      <Box
        overflow="hidden"
        fontSize="1rem"
        color={name ? 'black' : 'gray.400'}
        css={{ textWrap: 'nowrap' }}
      >
        {name ? truncate(name, { length: 60 }) : 'Нет имени'}
      </Box>
      {onRemove && (
        <IconButton
          aria-label={'Delete'}
          variant="ghost"
          width="24px"
          height="24px"
          minW="24px"
          icon={<Icon as={SmallCloseIcon} boxSize="14px" />}
          onClick={onRemove}
        />
      )}
    </Grid>
  )
}
